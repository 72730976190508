import { call, put, select, takeLatest } from 'redux-saga/effects';

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import * as yup from 'yup';

import { createReducer } from 'airshare-web-utils/redux-helpers';

import { authAPI } from '~/lib/api';
import { setError, SET_ERROR } from './session';

// actions
export const INIT = 'change-password/INIT';
export const UPDATE_FORM = 'change-password/UPDATE_FORM';
export const SUBMIT_FORM = 'change-password/SUBMIT_FORM';
export const SUBMIT_SUCCEEDED = 'change-password/SUBMIT_SUCCEEDED';
export const SUBMIT_FAILED = 'change-password/SUBMIT_FAILED';

// action creators
export const updateForm = (patch) => ({ type: UPDATE_FORM, payload: patch });
export const submitForm = () => ({ type: SUBMIT_FORM });

// form utils
const formSchema = yup.object().shape({
  email: yup.string().default('').required('Email is required'),
  resetKey: yup.string().default('').required('Reset key is required'),
  newPassword: yup
    .string()
    .default('')
    .min(8, 'Password must be at least 9 characters long')
    .required('New Password is required'),
  confirmPassword: yup
    .string()
    .default('')
    .required('Confirm Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const defaultForm = {
  form: formSchema.default(),
  errors: null,
  isDirty: false,
};

const handleFormUpdate = ({ form, ...otherProps }, patch) => {
  const newForm = { ...form, ...patch };

  const newState = {
    ...otherProps,
    form: newForm,
    isDirty: true,
  };

  try {
    formSchema.validateSync(newForm, { abortEarly: false });
    newState.errors = null;
  } catch (error) {
    newState.errors = error.inner.reduce(
      (acc, e) => ({ ...acc, [e.path]: e.errors }),
      {}
    );
  }

  return newState;
};

// reducers
const formModelReducer = createReducer(
  {
    [INIT]: () => defaultForm,
    [UPDATE_FORM]: (state, { payload }) => handleFormUpdate(state, payload),
    [SUBMIT_FORM]: (state) => ({ ...state, isSubmitted: true }),
    [SUBMIT_SUCCEEDED]: () => defaultForm,
  },
  defaultForm
);

const validationMessageReducer = createReducer({
  [INIT]: () => null,
  [SUBMIT_FORM]: () => null,
  [UPDATE_FORM]: () => null,
  [SUBMIT_FAILED]: (state, { payload }) => payload || '',
});

const isSubmittingReducer = createReducer(
  {
    [INIT]: () => false,
    [SUBMIT_FORM]: () => true,
    [SUBMIT_FAILED]: () => false,
    [SUBMIT_SUCCEEDED]: () => false,
    [SET_ERROR]: () => false,
  },
  false
);

export const reducer = combineReducers({
  formModel: formModelReducer,
  validationMessage: validationMessageReducer,
  isSubmitting: isSubmittingReducer,
});

// selectors
const selectLocalState = (state) => state.changePassword;

const selectFormModel = createSelector(
  [selectLocalState],
  (state) => state.formModel
);

export const selectForm = createSelector(
  [selectFormModel],
  (formModel) => formModel.form
);

export const selectErrors = createSelector(
  [selectFormModel],
  (formModel) => formModel.errors
);

export const selectVisibleErrors = createSelector(
  [selectFormModel],
  (formModel) => (formModel.isSubmitted ? formModel.errors : {}) || {}
);

export const selectValidationMessage = createSelector(
  [selectLocalState],
  (state) => state.validationMessage
);

export const selectIsSubmitting = createSelector(
  [selectLocalState],
  (state) => state.isSubmitting
);

// sagas
export function* saga() {
  yield put({ type: INIT });
  yield takeLatest(SUBMIT_FORM, onSubmit);
}

function* onSubmit() {
  try {
    const form = yield select(selectForm);
    const errors = yield select(selectErrors);

    if (errors) {
      yield put({ type: SUBMIT_FAILED });
      return;
    }

    yield call(authAPI.post, '/v2/change-password', form);
    yield put({ type: SUBMIT_SUCCEEDED });
  } catch (error) {
    const { response } = error;

    if (response && response.status === 400) {
      yield put({
        type: SUBMIT_FAILED,
        payload: response.data.message || response.data,
      });
    } else {
      yield put(setError(error));
    }
  }
}
