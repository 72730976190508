import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';

import { formatDate, parseDate } from 'react-day-picker/moment';

import FormField from '../form-field';
import { MonthCaption } from '../calendar/calendar';

import './date-field.scss';
import '../calendar/calendar.scss';

const DateField = ({
  className,
  disabled = false,
  onChange,
  required = false,
  value,
  ...formFieldProps
}) => (
  <FormField
    className={classnames(className, 'airshare-date-field')}
    disabled={disabled}
    required={required}
    {...formFieldProps}
  >
    <InputControl
      className={className}
      disabled={disabled}
      onChange={onChange}
      required={required}
      value={value}
    />
  </FormField>
);

const InputControl = ({
  className,
  disabled,
  onBlur,
  onChange,
  onFocus,
  required,
  value,
}) => (
  <DayPickerInput
    dayPickerProps={{
      captionElement: MonthCaption,
      className: 'airshare-calendar',
      locale: moment.locale(),
      showOutsideDays: true,
    }}
    formatDate={formatDate}
    inputProps={{
      className: classnames(
        'airshare-date-input',
        'bordered-control',
        'input-control',
        className
      ),
      disabled,
      required,
      onBlur,
      onFocus,
    }}
    onDayChange={onChange}
    parseDate={parseDate}
    placeholder="dd / mm / yyyy"
    value={value}
  />
);

InputControl.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

InputControl.defaultProps = {
  className: null,
  disabled: false,
  required: false,
  onFocus: () => {},
  onBlur: () => {},
  value: '',
};

DateField.propTypes = {
  ...FormField.propTypes,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

DateField.defaultProps = {
  className: null,
  disabled: false,
  required: false,
  value: '',
};
export default DateField;
