/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Select from 'airshare-react-components/select';
import TextArea from 'airshare-react-components/textarea-field';

import { routePaths } from '~/lib/constants';

import './flight-request-approval-screen.scss';

const ApprovalForm = ({
  flightRequest,
  form,
  isSubmitting,
  onFieldUpdate,
  onSubmit,
}) => {
  const { statusCodes } = flightRequest;
  const isActionable = statusCodes && statusCodes.length > 0;

  return (
    <>
      <TextArea
        className="note-input"
        label="Add Note for ATC"
        value={form.atcNote}
        disabled={isSubmitting}
        onChange={(atcNote) => {
          onFieldUpdate({ atcNote });
        }}
        testid="atc-web:flight-request-approval-screen:add-note-to-atc"
      />

      {isActionable && (
        <>
          <TextArea
            className="note-input"
            label="Operator Note"
            value={form.operatorNote}
            disabled={isSubmitting}
            onChange={(operatorNote) => {
              onFieldUpdate({ operatorNote });
            }}
            testid="atc-web:flight-request-approval-screen:operator-note"
          />
          <Select
            label="Decision"
            value={form.newStatus}
            disabled={isSubmitting}
            onChange={(newStatus) => {
              onFieldUpdate({ newStatus });
            }}
            data-testid="atc-web:flight-request-approval-screen:decision-dropdown"
          >
            {statusCodes.map(({ code, label }) => (
              <option
                value={code}
                key={code}
                data-testid="atc-web:flight-request-approval-screen:decision-dropdown-value"
              >
                {label}
              </option>
            ))}
          </Select>
        </>
      )}

      <div className="buttons">
        <button
          className="btn-primary btn-s"
          type="button"
          disabled={isSubmitting}
          onClick={onSubmit}
          data-testid="atc-web:flight-request-approval-screen:save-button"
        >
          {isSubmitting
            ? '...Submitting'
            : !isActionable
              ? 'Save'
              : form.newStatus
                ? 'Submit with decision'
                : 'Save as draft'}
        </button>

        <Link
          className="btn-primary btn-s"
          to={routePaths.flightRequests}
          data-testid="atc-web:flight-request-approval-screen:cancel-button"
        >
          Cancel
        </Link>
      </div>
    </>
  );
};

ApprovalForm.propTypes = {
  flightRequest: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  isSubmitting: PropTypes.bool,
  onFieldUpdate: PropTypes.func,
  onSubmit: PropTypes.func,
};

ApprovalForm.defaultProps = {
  isSubmitting: false,
  onFieldUpdate: () => {},
  onSubmit: () => {},
};

export default ApprovalForm;
