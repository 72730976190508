import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import FormField from '../form-field';

import './select.scss';

const Select = ({
  children,
  className,
  disabled = false,
  onChange,
  placeholder = '',
  required = false,
  value,
  ...formFieldProps
}) => (
  <FormField
    className={classnames(className, 'airshare-select', {
      'has-value': value !== null && value !== '',
    })}
    disabled={disabled}
    required={required}
    {...formFieldProps}
  >
    <select
      className="input-control bordered-control"
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      required={required}
      value={value}
      data-testid="atc-web:select"
    >
      <option className="placeholder" value="">
        {placeholder}
      </option>
      {children}
    </select>
  </FormField>
);

Select.propTypes = {
  ...FormField.propTypes,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any, // eslint-disable-line
};

Select.defaultProps = {
  className: null,
  children: [],
  onChange: () => {},
  disabled: false,
  placeholder: '',
  required: false,
  value: '',
};

export default Select;
