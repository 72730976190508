import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import orderBy from 'lodash/orderBy';

import { takeLatest, put, call, fork } from 'redux-saga/effects';

import { createReducer } from 'airshare-web-utils/redux-helpers';

import { atcAPI } from '~/lib/api';

import { setError, SET_ERROR } from './session';

// action types
const FETCH = 'control-zone-configs/FETCH';
export const FETCH_SUCCEEDED = 'control-zone-configs/FETCH_SUCCEEDED';
export const FOCUS_ONE = 'control-zone-configs/FOCUS_ONE';

// action creators
export const fetch = () => ({ type: FETCH });
export const focusOne = (czConfig) => ({ type: FOCUS_ONE, payload: czConfig });

// reducers
const listReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: (state, { payload }) => orderBy(payload, ['name']),
  },
  []
);

const focusedReducer = createReducer({
  [FETCH_SUCCEEDED]: () => null,
  [FOCUS_ONE]: (_, { payload }) => payload.controlZoneCode,
});

const isLoadingReducer = createReducer(
  {
    [FETCH]: () => true,
    [FETCH_SUCCEEDED]: () => false,
    [SET_ERROR]: () => false,
  },
  false
);

export const reducer = combineReducers({
  list: listReducer,
  focused: focusedReducer,
  isLoading: isLoadingReducer,
});

// selectors
const selectLocalState = (state) => state.controlZoneConfigs;

export const selectList = createSelector(
  [selectLocalState],
  (state) => state.list
);

export const selectFocused = createSelector(
  [selectLocalState],
  (state) => state.focused
);

export const selectIsLoading = createSelector(
  [selectLocalState],
  (state) => state.isLoading
);

export const selectCount = createSelector([selectList], (list) => list.length);

// sagas
export function* saga() {
  yield fork(onFetch);
  yield takeLatest(FETCH, onFetch);
}

function* onFetch() {
  try {
    const { data } = yield call(atcAPI.get, '/control-zone-configs');
    yield put({ type: FETCH_SUCCEEDED, payload: data });
  } catch (error) {
    yield put(setError(error));
  }
}
