import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import * as yup from 'yup';

import { createReducer } from 'airshare-web-utils/redux-helpers';
import { UserRole } from 'argus-common/enums';

import { authAPI } from '~/lib/api';
import { setError, SET_ERROR } from './session';

// actions
const INIT = 'log-in/INIT';
const UPDATE_FORM = 'log-in/UPDATE_FORM';
const SUBMIT_FORM = 'log-in/SUBMIT_FORM';
export const SUBMIT_SUCCEEDED = 'log-in/SUBMIT_SUCCEEDED';
const SUBMIT_FAILED = 'log-in/SUBMIT_FAILED';

export const updateForm = (patch) => ({ type: UPDATE_FORM, payload: patch });
export const submitForm = (patch) => ({ type: SUBMIT_FORM, payload: patch });

// constants
export const Status = {
  IDLE: 'IDLE',
  SUBMITTING: 'SUBMITTING',
  SUCCESS: 'SUCCESS',
};

// reducers
const formSchema = yup.object().shape({
  userName: yup.string().default('').required('User name is required'),
  password: yup.string().default('').required('Password is required'),
});

const defaultForm = {
  form: formSchema.default(),
  errors: null,
  isDirty: false,
};

const handleFormUpdate = ({ form, ...otherProps }, patch) => {
  const newForm = { ...form, ...patch };

  const newState = {
    ...otherProps,
    form: newForm,
    isDirty: true,
  };

  try {
    formSchema.validateSync(newForm, { abortEarly: false });
    newState.errors = null;
  } catch (error) {
    newState.errors = error.inner.reduce(
      (acc, e) => ({ ...acc, [e.path]: e.errors }),
      {}
    );
  }

  return newState;
};

const formModelReducer = createReducer(
  {
    [INIT]: () => defaultForm,
    [UPDATE_FORM]: (state, { payload }) => handleFormUpdate(state, payload),
    [SUBMIT_FORM]: (state) => ({ ...state, isSubmitted: true }),
    [SUBMIT_SUCCEEDED]: () => defaultForm,
  },
  defaultForm
);

const validationMessageReducer = createReducer({
  [INIT]: () => null,
  [SUBMIT_FORM]: () => null,
  [UPDATE_FORM]: () => null,
  [SUBMIT_FAILED]: (state, { payload }) => payload || '',
});

const isSubmittingReducer = createReducer(
  {
    [INIT]: () => false,
    [SUBMIT_FORM]: () => true,
    [SUBMIT_FAILED]: () => false,
    [SUBMIT_SUCCEEDED]: () => false,
    [SET_ERROR]: () => false,
  },
  false
);

export const reducer = combineReducers({
  formModel: formModelReducer,
  validationMessage: validationMessageReducer,
  isSubmitting: isSubmittingReducer,
});

// selectors
const selectLocalState = (state) => state.logIn;

const selectFormModel = createSelector(
  [selectLocalState],
  (state) => state.formModel
);

export const selectForm = createSelector(
  [selectFormModel],
  (formModel) => formModel.form
);

export const selectErrors = createSelector(
  [selectFormModel],
  (formModel) => formModel.errors
);

export const selectVisibleErrors = createSelector(
  [selectFormModel],
  (formModel) => (formModel.isSubmitted ? formModel.errors : {}) || {}
);

export const selectValidationMessage = createSelector(
  [selectLocalState],
  (state) => state.validationMessage
);

export const selectIsSubmitting = createSelector(
  [selectLocalState],
  (state) => state.isSubmitting
);

// sagas
export function* saga() {
  yield put({ type: INIT });
  yield takeLatest(SUBMIT_FORM, onSubmit);
}

function* onSubmit() {
  try {
    const errors = yield select(selectErrors);

    if (errors) {
      yield put({ type: SUBMIT_FAILED });
      return;
    }

    const form = yield select(selectForm);
    const { data } = yield call(authAPI.post, '/v2/log-in', {
      ...form,
      origin: 'atc-web',
    });
    const { profile } = data;
    const validRoles = [UserRole.SUPER_ADMIN, UserRole.AUTHORISER];

    if (profile.roles.some((r) => validRoles.includes(r))) {
      yield put({ type: SUBMIT_SUCCEEDED, payload: data });
    } else {
      yield put({ type: SUBMIT_FAILED, payload: 'Unauthorized' });
    }
  } catch (error) {
    const { response } = error;

    if (response && response.status === 400) {
      yield put({ type: SUBMIT_FAILED, payload: response.data.message });
    } else {
      yield put(setError(error));
    }
  }
}
