import React from 'react';
import { versionDescription } from 'argus-common/version';

import './about-screen.scss';

export default function AboutScreen() {
  return (
    <div>
      <h1 className="about-heading">About</h1>
      <div>
        <b>Version:</b> {versionDescription}
      </div>
    </div>
  );
}
