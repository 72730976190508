/* eslint-disable react/prop-types */
import React from 'react';

import Select from 'airshare-react-components/select';
import connectToStore from 'airshare-web-utils/connect-to-redux';

import { ControlZoneFilters } from '~/lib/constants';

import {
  selectActiveControlZone,
  selectControlZones,
  changeControlZone,
} from '~/state/control-zones';

import { selectUserIsAdmin } from '~/state/session';

const ControlZoneFilter = ({
  userIsAdmin,
  controlZone,
  controlZones,
  onSelectControlZone,
}) => (
  <Select
    className="control-zone-filter"
    label="Zone"
    value={controlZone}
    onChange={onSelectControlZone}
    placeholder="Select a control zone"
  >
    {userIsAdmin && (
      <>
        <option
          value={ControlZoneFilters.ALL_CONTROL_ZONES}
          data-testid="atc-web:control-zone-filter:all-zones"
        >
          All Control Zones
        </option>
        <option
          value={ControlZoneFilters.ALL_FLIGHTS}
          data-testid="atc-web:control-zone-filter:all-flights"
        >
          All Flights
        </option>
      </>
    )}
    {controlZones &&
      controlZones.map(({ name, code }) => (
        <option
          value={code}
          key={code}
          data-testid="atc-web:control-zone-filter:value"
        >
          {name}
        </option>
      ))}
  </Select>
);

export default connectToStore(
  ControlZoneFilter,
  {
    userIsAdmin: selectUserIsAdmin,
    controlZone: selectActiveControlZone,
    controlZones: selectControlZones,
  },
  {
    onSelectControlZone: changeControlZone,
  }
);
