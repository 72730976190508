import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classnames from 'classnames';

import TabBar from './tab-bar';

const ButtonTabBar = ({
  children,
  className,
  disabled,
  boxed,
  onChange,
  value,
}) => (
  <TabBar className={className} boxed={boxed}>
    {React.Children.map(
      children,
      (child) =>
        child &&
        React.cloneElement(child, {
          className: classnames(child.props.className, {
            selected: value && isEqual(value, child.props.value),
          }),
          disabled,
          onClick: () => onChange(child.props.value),
        })
    )}
  </TabBar>
);

ButtonTabBar.propTypes = {
  ...TabBar.propTypes,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any, // eslint-disable-line
};

ButtonTabBar.defaultProps = {
  disabled: false,
};

export default ButtonTabBar;
