import { combineReducers } from 'redux';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { call, put, select } from 'redux-saga/effects';

import { createReducer } from 'airshare-web-utils/redux-helpers';
import { ControlZoneFilters } from '~/lib/constants';
import { atcAPI } from '~/lib/api';

import { setError } from './session';

// action types
const SET_LIST = 'control-zones/SET_LIST';
export const SELECT_CONTROL_ZONE = 'control-zones/SELECT_CONTROL_ZONE';

// action creators
export const changeControlZone = (controlZone) => ({
  type: SELECT_CONTROL_ZONE,
  payload: controlZone,
});

// reducers
const listReducer = createReducer({
  [SET_LIST]: (state, { payload }) => orderBy(payload, ['name']),
});

const selectedReducer = createReducer(
  {
    [SELECT_CONTROL_ZONE]: (state, { payload }) => payload,
  },
  ControlZoneFilters.ALL_CONTROL_ZONES
);

export const reducer = combineReducers({
  list: listReducer,
  selected: selectedReducer,
});

// selectors
const selectLocalState = (state) => state.controlZones;

export const selectControlZones = createSelector(
  [selectLocalState],
  (state) => state.list || []
);

export const selectActiveControlZone = createSelector(
  [selectLocalState],
  (state) => state.selected
);

export const selectCenterCoordinates = createSelector(
  [selectControlZones, selectActiveControlZone],
  (controlZones, activeControlZone) => {
    const cz = controlZones.find((c) => c.code === activeControlZone);

    if (cz && cz.lat && cz.lng) {
      return [cz.lng, cz.lat];
    }

    return null;
  }
);

// sagas
export function* saga(userProfile) {
  try {
    const { data } = yield call(atcAPI.get, '/control-zones');
    yield put({ type: SET_LIST, payload: data });

    // Automatically select the first matching control
    // zone for the given user....if there is one
    const userCZs = userProfile.controlZones;
    const controlZones = yield select(selectControlZones);

    if (userCZs && userCZs.length > 0) {
      const matching = controlZones.find((cz) => userCZs.includes(cz.code));

      if (matching) {
        yield put(changeControlZone(matching.code));
      }
    }
  } catch (error) {
    yield put(setError(error));
  }
}
