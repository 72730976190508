import { initSentry } from 'airshare-web-utils/sentry';

initSentry({ app: 'argus-atc-web' });

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import moment from 'moment';

import detectTouchDevice from 'airshare-web-utils/detect-touch-device';

import App from './components/app/app';
import store, { startSagas } from './state/store';
import { logOut } from './state/session';

import 'airshare-web-styles/rules/layout.scss';
import 'airshare-web-styles/rules/typography.scss';
import 'airshare-web-styles/rules/buttons.scss';
import 'airshare-web-styles/rules/tables.scss';
import 'airshare-web-styles/rules/callouts.scss';
import 'airshare-web-styles/fonts/index.scss';

import { atcAPI, authAPI } from './lib/api';

// Where the device has touch events but not mouse events,
// add the 'touch-only' css class to the body tag
document.body.className += detectTouchDevice() ? ' touch-only' : '';

// Handle 401 errors by logging the user out
atcAPI.handleErrorStatus(
  401,
  () => {
    store.dispatch(logOut());
  },
  'atc-web',
  `${window.env.ARGUS_AUTH_API_URL}/v2/token-refresh`
);

authAPI.handleErrorStatus(
  401,
  () => {
    store.dispatch(logOut());
  },
  'atc-web',
  `${window.env.ARGUS_AUTH_API_URL}/v2/token-refresh`
);

// Start the redux sagas after everything else
// has been initialized.
startSagas();

// Update touch device css class
document.body.className += detectTouchDevice() ? ' touch-only' : '';

// Set locale
moment.locale(`${window.env.DEFAULT_LOCALE}`);

// Render the application
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
);
