export const routePaths = {
  logIn: '/log-in',
  resetPassword: '/reset-password',
  changePassword: '/change-password',
  flightRequests: '/flight-requests',
  flightApproval: '/flight-requests/:id',
  visualFlightRequests: '/visual-flight-requests',
  users: '/users',
  editUser: '/users/:id',
  controlZones: '/control-zones',
  controlZoneConfigs: '/control-zone-configs',
  controlZoneConfigForm: '/control-zone-configs/:code',
  about: '/about',
};

export const LogInStatus = {
  IDLE: 'IDLE',
  LOG_IN_ATTEMPT_IN_PROGRESS: 'LOG_IN_ATTEMPT_IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
};

export const ControlZoneFilters = {
  ALL_CONTROL_ZONES: 'ALL_CONTROL_ZONES',
  ALL_FLIGHTS: 'ALL_FLIGHTS',
};
