import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Portal } from 'react-portal';

import { childrenPropType } from 'airshare-web-utils/prop-types';

import Overlay from '../overlay';

import './dialog.scss';

const Dialog = ({ children, className, isOpen = false, onClose }) => (
  <Portal>
    <Overlay
      className="airshare-dialog-overlay"
      isVisible={isOpen}
      onClick={onClose}
    />
    <div
      className={classnames(className, 'airshare-dialog', {
        'is-visible': isOpen,
      })}
    >
      <button
        type="button"
        className="close-button"
        aria-label="close"
        onClick={onClose}
      />
      {children}
    </div>
  </Portal>
);

Dialog.propTypes = {
  children: childrenPropType,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

Dialog.defaultProps = {
  children: null,
  className: null,
};

export default Dialog;
