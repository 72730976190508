import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { childrenPropType } from 'airshare-web-utils/prop-types';

export const DialogContent = ({ children, className }) => (
  <div className={classnames(className, 'airshare-dialog-content')}>
    {children}
  </div>
);

export const DialogTitle = ({ children, className }) => (
  <div className={classnames(className, 'airshare-dialog-title')}>
    {children}
  </div>
);

export const DialogFooter = ({ children, className }) => (
  <div className={classnames(className, 'airshare-dialog-footer')}>
    {children}
  </div>
);

const allPropTypes = {
  children: childrenPropType,
  className: PropTypes.string,
};

const allDefaultProps = {
  children: null,
  className: null,
};

DialogContent.propTypes = allPropTypes;
DialogContent.defaultProps = allDefaultProps;
DialogTitle.propTypes = allPropTypes;
DialogTitle.defaultProps = allDefaultProps;
DialogFooter.propTypes = allPropTypes;
DialogFooter.defaultProps = allDefaultProps;
