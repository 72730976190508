import detectTouchDevice from 'airshare-web-utils/detect-touch-device';
import { createButton } from './_shared';

import './use-zooming.scss';

export default (google, map) => {
  // Zooming is handled using touch events (pinching)
  // on touch devices. Only enable zoom buttons
  // for non-touch devices
  const isTouch = detectTouchDevice();

  if (isTouch) {
    return;
  }

  // Build controls
  const zoomControls = document.createElement('div', {});
  zoomControls.classList.add('airshare-map-zoom-controls');

  zoomControls.appendChild(
    createButton({
      className: 'zoom-in',
      onClick: () => map.setZoom(map.getZoom() + 1),
    })
  );

  zoomControls.appendChild(
    createButton({
      className: 'zoom-out',
      onClick: () => map.setZoom(map.getZoom() - 1),
    })
  );

  // insert into map
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControls);
};
