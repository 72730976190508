/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';

import connectToStore from 'airshare-web-utils/connect-to-redux';

import {
  selectList,
  selectFocused,
  focusOne,
} from '~/state/control-zone-configs';

import './control-zone-configs.scss';

function ControlZoneConfigs({ list, focused, onFocusOne }) {
  return (
    <div
      className="control-zone-configs"
      data-testid="atc-web:control-zone-configs"
    >
      <table className="primary-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Auto approval</th>
          </tr>
        </thead>
        <tbody>
          {list.map((cz) => (
            <tr
              key={cz.controlZoneCode}
              className={classnames({
                focused: focused === cz.controlZoneCode,
              })}
              onClick={() => onFocusOne(cz)}
              data-testid="atc-web:control-zone-configs:list-item"
            >
              <td data-testid="atc-web:control-zone-configs:list-item-name">
                {cz.name}
              </td>
              <td data-testid="atc-web:control-zone-configs:list-item-status">
                {cz.autoApprovalEnabled ? 'On' : 'Off'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default connectToStore(
  ControlZoneConfigs,
  {
    list: selectList,
    focused: selectFocused,
  },
  {
    onFocusOne: focusOne,
  }
);
