/* eslint-disable react/prop-types */
import React from 'react';

import { Switch, Route, NavLink, Redirect } from 'react-router-dom';

import connectToStore from 'airshare-web-utils/connect-to-redux';
import AppBar from 'airshare-react-components/app-bar';

import { routePaths } from '~/lib/constants';
import { logOut } from '~/state/session';

import FlightRequestsScreen from '../flight-requests-screen/flight-requests-screen';
import FlightRequestApprovalScreen from '../flight-request-approval-screen/flight-request-approval-screen';
import VisuaFlightRequestsScreen from '../visual-flight-requests-screen/visual-flight-requests-screen';
import ControlZoneConfigsScreen from '../control-zone-configs-screen/control-zone-configs-screen';

import './private-layout.scss';
import AboutScreen from '../about/about-screen';

function PrivateLayout({ onLogOutClick }) {
  return (
    <div className="private-layout">
      <AppBar>
        <h6 data-testid="atc-web:main-header">AIRWAYS ATC</h6>
        <div className="nav" data-testid="atc-web:nav-bar">
          <NavLink
            to={routePaths.flightRequests}
            exact
            data-testid="atc-web:nav-bar:flights"
          >
            FLIGHTS
          </NavLink>
          <NavLink
            to={routePaths.visualFlightRequests}
            data-testid="atc-web:nav-bar:visual-flights"
          >
            VISUAL FLIGHTS FOR THE DAY
          </NavLink>
          <NavLink
            to={routePaths.controlZoneConfigs}
            data-testid="atc-web:nav-bar:control-zone-config"
          >
            CONTROL ZONE CONFIG
          </NavLink>
          <button
            onClick={onLogOutClick}
            type="button"
            data-testid="atc-web:nav-bar:logout-button"
          >
            LOG OUT
          </button>
        </div>
      </AppBar>

      <div className="airshare-main-content">
        <Switch>
          <Route
            path={routePaths.flightRequests}
            component={FlightRequestsScreen}
            exact
          />
          <Route
            path={routePaths.flightApproval}
            component={FlightRequestApprovalScreen}
          />
          <Route
            path={routePaths.visualFlightRequests}
            component={VisuaFlightRequestsScreen}
            exact
          />
          <Route
            path={routePaths.controlZoneConfigs}
            component={ControlZoneConfigsScreen}
            exact
          />
          <Route path={routePaths.about} component={AboutScreen} exact />
          <Redirect to={routePaths.flightRequests} />
        </Switch>
      </div>
    </div>
  );
}

export default connectToStore(PrivateLayout, {}, { onLogOutClick: logOut });
