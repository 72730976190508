import {
  isFullscreen,
  requestFullscreen,
  exitFullscreen,
} from 'airshare-web-utils/full-screen';

import { deviceIsAPhone, createButton } from './_shared';

import './use-fullscreen-button.scss';

export default (google, map) => {
  if (deviceIsAPhone() || !map || !google) {
    return;
  }

  const b = createButton({
    className: 'airshare-map-fullscreen-button',
    onClick: () => {
      const el = map.getDiv().firstChild;

      if (isFullscreen(el)) {
        exitFullscreen();
        b.classList.remove('full-screen');
      } else {
        requestFullscreen(el);
        b.classList.add('full-screen');
      }
    },
  });

  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(b);
};
