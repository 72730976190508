import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { call, takeLatest, put } from 'redux-saga/effects';

import {
  createAction,
  createReducer,
  LOG_OUT,
} from 'airshare-web-utils/redux-helpers';
import { UserRole } from 'argus-common/enums';

import { authAPI, atcAPI } from '~/lib/api';

// Actions
export const SET_PROFILE = 'session/SET_PROFILE';
export const SET_ERROR = 'session/SET_ERROR';
export const CLEAR_ERROR = 'session/CLEAR_ERROR';

export const logOut = createAction(LOG_OUT);
export const setProfile = createAction(SET_PROFILE);
export const setError = createAction(SET_ERROR);
export const clearError = createAction(CLEAR_ERROR);

// Reducers
const errorReducer = createReducer({
  [SET_ERROR]: (state, { payload }) => {
    if (window.env.CONSOLE_LOG_ERRORS) {
      console.error(payload); // eslint-disable-line
    }

    return payload.message || !payload.toString ? payload : payload.toString();
  },
  [CLEAR_ERROR]: () => null,
});

const profileReducer = createReducer({
  [SET_PROFILE]: (state, { payload }) => payload,
});

export const reducer = combineReducers({
  profile: profileReducer,
  error: errorReducer,
});

// Selectors
export const getLocalState = (state) => state.session;

export const selectUserProfile = createSelector(
  [getLocalState],
  (state) => state.profile
);

export const selectError = createSelector(
  [getLocalState],
  (state) => state.error
);

export const selectUserControlZones = createSelector(
  [selectUserProfile],
  ({ profile }) => (profile ? profile.controlZones || [] : [])
);

export const selectUserIsAdmin = createSelector(
  [selectUserProfile],
  ({ profile }) =>
    profile && profile.roles && profile.roles.includes(UserRole.SUPER_ADMIN)
);

// Sagas
export function* saga() {
  yield takeLatest(LOG_OUT, onLogOut);
  yield takeLatest(SET_PROFILE, onSetProfile);

  // Check local storage for profile
  const profileJson = yield call([localStorage, 'getItem'], 'profile');
  const refreshToken = yield call([localStorage, 'getItem'], 'refreshToken');

  if (profileJson) {
    // Set profile
    const profile = JSON.parse(profileJson);
    yield put(setProfile({ profile, refreshToken }));
  }
}

function* onSetProfile({ payload }) {
  const { profile, refreshToken } = payload;
  yield call([localStorage, 'setItem'], 'profile', JSON.stringify(profile));
  yield call([localStorage, 'setItem'], 'refreshToken', refreshToken);
  yield call(authAPI.setAuthHeader, profile.token);
  yield call(atcAPI.setAuthHeader, profile.token);
}

function* onLogOut() {
  // Logout so that we clear the refresh token on the server
  const logoutForm = { origin: 'atc-web' };
  yield call(authAPI.post, '/v2/log-out', logoutForm);

  yield call([localStorage, 'clear']);
  yield call(authAPI.clearAuthHeader);
  yield call(atcAPI.clearAuthHeader);
}
