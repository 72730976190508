/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';

import ReactJson from 'react-json-view';
import classnames from 'classnames';

import { ErrorBoundary } from 'common-ui-components';
import connectToStore from 'airshare-web-utils/connect-to-redux';
import { ButtonTabBar } from 'airshare-react-components/tabs';

import {
  fetch,
  viewTab,
  updateForm,
  submitForm,
  selectCanAction,
  selectFlightRequest,
  selectIsLoading,
  selectIsSubmitting,
  selectRawFlightRequest,
  selectActiveTab,
  selectForm,
  selectValidationError,
} from '~/state/flight-request-approval';

import Map from '../map/map';
import ApprovalForm from './_approval-form';
import Summary from './summary';

import './flight-request-approval-screen.scss';

const FlightRequestApprovalScreen = ({
  flightRequest,
  canAction,
  isLoading,
  isSubmitting,
  rawFlightRequest,
  tab,
  form,
  validationError,
  match,
  onInit,
  onClickTab,
  onChange,
  onSubmit,
}) => {
  const flightRequestId = match.params.id;
  const formRef = useRef();

  useEffect(() => {
    onInit(flightRequestId);
  }, []);

  if (isLoading) {
    return <div className="loading-animation">Loading</div>;
  }

  if (!flightRequest) {
    return (
      <div className="loading-animation">
        No flight request found for {flightRequestId}
      </div>
    );
  }

  return (
    <div
      className="flight-request-approval-screen"
      data-testid="atc-web:flight-request-approval-screen"
    >
      <h4 data-testid="atc-web:flight-request-approval-screen:header">
        {' '}
        Flight Notification - ID: {flightRequest.flightId}
      </h4>

      {rawFlightRequest && (
        <ButtonTabBar
          className={classnames({ hidden: !rawFlightRequest })}
          value={tab}
          onChange={onClickTab}
        >
          <button
            type="button"
            value="summary"
            data-testid="atc-web:flight-request-approval-screen:approval-form-button"
          >
            Approval form
          </button>
          <button
            type="button"
            value="raw"
            data-testid="atc-web:flight-request-approval-screen:raw button"
          >
            Raw
          </button>
        </ButtonTabBar>
      )}

      <div className={classnames({ hidden: tab !== 'summary' })}>
        <Summary formRef={formRef} flightRequest={flightRequest} />

        {canAction && (
          <ApprovalForm
            flightRequest={flightRequest}
            form={form}
            isSubmitting={isSubmitting}
            onFieldUpdate={onChange}
            onSubmit={() => onSubmit(formRef.current.outerHTML)}
          />
        )}

        {validationError && (
          <div
            className="danger-callout"
            data-testid="atc-web:flight-request-approval-screen:validation-error"
          >
            {validationError.message}
          </div>
        )}

        <Map
          zoom={13}
          center={flightRequest.deadCenter}
          flightRequests={[flightRequest]}
        />
      </div>

      {rawFlightRequest && (
        <div className={classnames({ hidden: tab !== 'raw' })}>
          <ErrorBoundary>
            <ReactJson
              src={rawFlightRequest}
              collapsed={1}
              theme="monokai"
              name={false}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
};

export default connectToStore(
  FlightRequestApprovalScreen,
  {
    flightRequest: selectFlightRequest,
    canAction: selectCanAction,
    isLoading: selectIsLoading,
    isSubmitting: selectIsSubmitting,
    rawFlightRequest: selectRawFlightRequest,
    tab: selectActiveTab,
    form: selectForm,
    validationError: selectValidationError,
  },
  {
    onInit: fetch,
    onClickTab: viewTab,
    onChange: updateForm,
    onSubmit: submitForm,
  }
);
