import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

const createDispatcher = (dMap) => (dispatch) =>
  bindActionCreators(dMap, dispatch);

const reduxConnect = (component, stateMap, dispatchMap) => {
  const mapStateMapToProps = createStructuredSelector(stateMap);
  const mapStateToProps = (state, ...otherProps) => ({
    ...mapStateMapToProps(state),
    ...otherProps,
  });

  const mapDispatchToProps = createDispatcher(dispatchMap);
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default reduxConnect;
