import {
  call,
  take,
  put,
  select,
  fork,
  cancel,
  delay,
} from 'redux-saga/effects';
import { combineReducers } from 'redux';
import moment from 'moment';
import { createSelector } from 'reselect';
import { createReducer } from 'airshare-web-utils/redux-helpers';

import { atcAPI } from '~/lib/api';
import { setError } from './session';

const storeDateFormat = 'YYYY-MM-DD';

// action types
const FETCH_SUCCEEDED = 'visual-flight-requests/FETCH_SUCCEEDED';
const FETCH = 'visual-flight-requests/FETCH';
const NEXT_DAY = 'visual-flight-requests/NEXT_DAY';
const PREVIOUS_DAY = 'visual-flight-requests/PREVIOUS_DAY';
const SET_TODAY = 'visual-flight-requests/SET_TODAY';

// action creators
export const fetch = () => ({ type: FETCH });
export const goToToday = () => ({ type: SET_TODAY });
export const goToNextDay = () => ({ type: NEXT_DAY });
export const goToPreviousDay = () => ({ type: PREVIOUS_DAY });

// reducers
const listReducer = createReducer({
  [FETCH_SUCCEEDED]: (state, { payload }) => payload,
});

const lastUpdatedReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: () => moment().format('HH:mm DD/MM/YYYY'),
  },
  moment().format('HH:mm DD/MM/YYYY')
);

const dateReducer = createReducer(
  {
    [NEXT_DAY]: (state) => moment(state).add(1, 'day').format(storeDateFormat),
    [PREVIOUS_DAY]: (state) =>
      moment(state).subtract(1, 'day').format(storeDateFormat),
    [SET_TODAY]: () => moment().format(storeDateFormat),
  },
  moment().format(storeDateFormat)
);

export const reducer = combineReducers({
  list: listReducer,
  lastUpdated: lastUpdatedReducer,
  date: dateReducer,
});

// selectors
const selectLocalState = (state) => state.visualFlightRequests;

export const selectFlightRequests = createSelector(
  [selectLocalState],
  (state) => state.list
);

export const selectLastUpdated = createSelector(
  [selectLocalState],
  (state) => state.lastUpdated
);

export const selectDate = createSelector(
  [selectLocalState],
  (state) => state.date
);

export const selectDateProps = createSelector([selectDate], (date) => {
  const selectedDate = moment(date);
  const isToday = moment().isSame(selectedDate, 'day');
  const dateText = isToday ? 'Today' : selectedDate.format('L');

  return {
    isToday,
    dateText,
  };
});

// sagas
export function* saga() {
  let task;

  while (true) {
    task = yield fork(onFetchFlightRequests);
    yield take([SET_TODAY, NEXT_DAY, PREVIOUS_DAY]);
    yield cancel([task]);
  }
}

function* onFetchFlightRequests() {
  yield delay(500);

  try {
    // identify query date
    const date = yield select(selectDate);

    if (!date) {
      return;
    }

    // build query
    const startMoment = moment(date).startOf('day');

    const params = {
      startDateTime: startMoment.toISOString(),
      endDateTime: startMoment.clone().endOf('day').toISOString(),
    };

    // fetch flight requests
    const { data } = yield call(atcAPI.get, '/flight-requests', { params });

    // dispatch to store
    yield put({ type: FETCH_SUCCEEDED, payload: data });
  } catch (error) {
    yield put(setError(error));
  }
}
