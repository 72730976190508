/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import connectToStore from 'airshare-web-utils/connect-to-redux';
import TextField from 'airshare-react-components/text-field';
import DateField from 'airshare-react-components/date-field';

import { routePaths } from '~/lib/constants';

import {
  selectSortedFlightRequests,
  selectFilter,
  selectIsLoading,
  selectDoFiltering,
  changeFilter,
  applyFilter,
  toggleFiltering,
  doSorting,
} from '~/state/flight-requests';

import { selectFlightRequest } from '~/state/flight-request-approval';

import ControlZoneFilter from '../control-zone-filter';

import './flight-requests-screen.scss';

const FlightRequestsScreen = ({
  isLoading,
  flightRequests,
  selectedFlightRequest,
  filter,
  doFiltering,
  onChangeFilter,
  onApplyFilter,
  onToggleFilter,
  onSort,
}) => {
  const { id, date } = filter;

  return (
    <div
      className="flight-requests-screen"
      data-testid="atc-web:flight-request-screen"
    >
      <div className="control-buttons">
        <div
          className="hyperlink"
          onClick={onToggleFilter}
          data-testid="atc-web:flight-request-screen:filtering-link"
        >
          {doFiltering ? 'Simple Filtering' : 'Advanced Filtering'}
        </div>
        <Link
          to={routePaths.visualFlightRequests}
          className="hyperlink"
          data-testid="atc-web:flight-request-screen:visual-flight-link"
        >
          Visual Flights for the Day
        </Link>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onApplyFilter();
        }}
        noValidate
        autoComplete="off"
      >
        <ControlZoneFilter />

        {doFiltering && (
          <>
            <TextField
              label="ID"
              value={id ? id.toString() : ''}
              onChange={(val) =>
                onChangeFilter({ id: isEmpty(val) ? null : val })
              }
              testid="atc-web:flight-request-screen:filtering:id-text"
            />
            <DateField
              label="Date"
              value={date || ''}
              onChange={(val) => onChangeFilter({ date: val })}
              data-testid="atc-web:flight-request-screen:filtering:date"
            />
            <button
              type="submit"
              className="apply-button btn-secondary btn-s"
              data-testid="atc-web:flight-request-screen:filtering:apply-button"
            >
              Apply
            </button>
          </>
        )}
      </form>

      {!isLoading && flightRequests && flightRequests.length > 0 && (
        <table
          className="primary-table striped-table"
          data-testid="atc-web:flight-request-screen:flights-table"
        >
          <thead>
            <tr>
              <th
                onClick={() => onSort('flightId')}
                data-testid="atc-web:flight-request-screen:flights-table:id"
              >
                ID
              </th>
              <th
                onClick={() => onSort('controlZoneName')}
                data-testid="atc-web:flight-request-screen:flights-table:tower"
              >
                Tower
              </th>
              <th
                onClick={() => onSort('createdDateTime')}
                data-testid="atc-web:flight-request-screen:flights-table:logged-date"
              >
                Logged
              </th>
              <th
                onClick={() => onSort('startDateTime')}
                data-testid="atc-web:flight-request-screen:flights-table:flight-date"
              >
                Flight Date
              </th>
              <th
                onClick={() => onSort('leadTimeMinutes')}
                data-testid="atc-web:flight-request-screen:flights-table:sla"
              >
                SLA
              </th>
              <th
                onClick={() => onSort('statusCode')}
                data-testid="atc-web:flight-request-screen:flights-table:state"
              >
                State
              </th>
              <th
                onClick={() => onSort('decisionStatus')}
                data-testid="atc-web:flight-request-screen:flights-table:action"
              >
                Action
              </th>
              <th
                onClick={() => onSort('operatingRule')}
                data-testid="atc-web:flight-request-screen:flights-table:rule"
              >
                Operating Rule
              </th>
              <th
                className="col-note"
                data-testid="atc-web:flight-request-screen:flights-table:note"
              >
                Note
              </th>
              <th
                onClick={() => onSort('decisionMakerName')}
                data-testid="atc-web:flight-request-screen:flights-table:authoriser"
              >
                Authoriser
              </th>
            </tr>
          </thead>
          <tbody>
            {flightRequests.map((f) => (
              <FlightRequestRow
                key={f.id}
                f={f}
                linkPath={routePaths.flightApproval.replace(':id', f.id)}
                selected={
                  selectedFlightRequest && selectedFlightRequest.id === f.id
                }
              />
            ))}
          </tbody>
        </table>
      )}

      {isLoading ? (
        <div className="loading-animation">Loading</div>
      ) : flightRequests !== null && flightRequests.length === 0 ? (
        <p className="no-data-message">
          There are no flight requests matching the specified criteria.
        </p>
      ) : null}
    </div>
  );
};

// eslint-disable-next-line react/display-name
const FlightRequestRow = React.memo(({ f, linkPath, selected }) => (
  <tr className={classnames({ selected })}>
    {[
      f.flightId,
      f.controlZoneName,
      moment(f.createdDateTime).format('HH:mm L'),
      moment(f.startDateTime).format('HH:mm L'),
      f.leadTimeCode,
      f.shortStatusLabel,
      f.decisionStatus,
      f.operatingRule,
      f.atcNote || f.latestAtcNote || '',
      f.decisionMakerName,
    ].map((val, i) => {
      if (i === 7) {
        return (
          <td key={`${f.flightId}-part102`}>
            {' '}
            {/* eslint-disable-line */}
            <Link to={linkPath} className="paragraph-m">
              <span
                className={`text-bubble ${
                  val === 'Part 102' ? 'warning-color' : 'disabled-color'
                }`}
              >
                {val}
              </span>
            </Link>
          </td>
        );
      }
      return (
        <td key={`${f.flightId}-${val}`}>
          {' '}
          {/* eslint-disable-line */}
          <Link
            to={linkPath}
            className="paragraph-m"
            data-testid={`atc-web:flight-request-screen:flights-table:value-${i}`}
          >
            <span>{val}</span>
          </Link>
        </td>
      );
    })}
  </tr>
));

export default connectToStore(
  FlightRequestsScreen,
  {
    isLoading: selectIsLoading,
    flightRequests: selectSortedFlightRequests,
    selectedFlightRequest: selectFlightRequest,
    doFiltering: selectDoFiltering,
    filter: selectFilter,
  },
  {
    onChangeFilter: changeFilter,
    onApplyFilter: applyFilter,
    onToggleFilter: toggleFiltering,
    onSort: doSorting,
  }
);
