import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import moment from 'moment';
import orderBy from 'lodash/orderBy';

import {
  call,
  put,
  race,
  delay,
  take,
  select,
  fork,
  cancel,
} from 'redux-saga/effects';

import { createReducer } from 'airshare-web-utils/redux-helpers';

import { atcAPI } from '~/lib/api';

import { setError, SET_ERROR } from './session';

import { SELECT_CONTROL_ZONE, selectActiveControlZone } from './control-zones';

// action types
const FETCH = 'flight-requests/FETCH';
const FETCH_SUCCEEDED = 'flight-requests/FETCH_SUCCEEDED';
const TOGGLE_FILTERING = 'flight-requests/TOGGLE_FILTERING';
const UPDATE_FILTER = 'flight-requests/UPDATE_FILTER';
const SORT = 'flight-requests/SORT';

// action creators
export const fetch = () => ({ type: FETCH });
export const changeFilter = (patch) => ({
  type: UPDATE_FILTER,
  payload: patch,
});
export const applyFilter = () => ({ type: FETCH });
export const toggleFiltering = () => ({ type: TOGGLE_FILTERING });
export const doSorting = (sortField) => ({ type: SORT, payload: sortField });

// reducers
const DescSortFields = ['createdDateTime', 'startDateTime'];

const sortOrderReducer = createReducer(
  {
    [SORT]: (state, { payload }) => ({
      sortField: payload,
      sortAscending:
        state.sortField === payload
          ? !state.sortAscending
          : !DescSortFields.includes(payload),
    }),
  },
  {
    sortField: 'startDateTime',
    sortAscending: true,
  }
);

const listReducer = createReducer({
  [FETCH]: () => [],
  [FETCH_SUCCEEDED]: (state, { payload }) => payload,
});

const doFilteringReducer = createReducer(
  {
    [TOGGLE_FILTERING]: (state) => !state,
  },
  false
);

const filterReducer = createReducer(
  {
    [UPDATE_FILTER]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {
    id: null,
    date: null,
  }
);

const isLoadingReducer = createReducer(
  {
    [FETCH]: () => true,
    [FETCH_SUCCEEDED]: () => false,
    [SET_ERROR]: () => false,
  },
  false
);

export const reducer = combineReducers({
  list: listReducer,
  filter: filterReducer,
  sortOrder: sortOrderReducer,
  isLoading: isLoadingReducer,
  doFiltering: doFilteringReducer,
});

// selectors
const selectLocalState = (state) => state.flightRequests;

export const selectFlightRequests = createSelector(
  [selectLocalState],
  (state) => state.list
);

export const selectFilter = createSelector(
  [selectLocalState],
  (state) => state.filter
);

export const selectSortOrder = createSelector(
  [selectLocalState],
  (state) => state.sortOrder
);

export const selectIsLoading = createSelector(
  [selectLocalState],
  (state) => state.isLoading
);

export const selectDoFiltering = createSelector(
  [selectLocalState],
  (state) => state.doFiltering
);

export const selectSortedFlightRequests = createSelector(
  [selectFlightRequests, selectSortOrder],
  (flightRequests, sortOrder) => {
    if (!flightRequests) {
      return [];
    }

    const { sortField, sortAscending } = sortOrder;
    const sortFields = [sortField];
    const sortOrders = [sortAscending ? 'asc' : 'desc'];

    if (sortField !== 'startDateTime') {
      sortFields.push('startDateTime');
      sortOrders.push('desc');
    }

    return orderBy(flightRequests, sortFields, sortOrders);
  }
);

// sagas
export function* saga() {
  yield fork(flightRequestsSaga);
  yield put({ type: FETCH });
}

function* flightRequestsSaga() {
  // Refresh flight requests every sixty seconds
  // or when an action to fetch flight requests
  // is dispatched.
  // Also make sure that any fetches in progress
  // are cancelled.
  let task;

  while (true) {
    yield race({
      delay: delay(60 * 1000),
      manualFetch: take([FETCH, SELECT_CONTROL_ZONE, TOGGLE_FILTERING]),
    });

    if (task) {
      yield cancel([task]);
    }

    task = yield fork(onFetch);
  }
}

function* onFetch() {
  try {
    const doFiltering = yield select(selectDoFiltering);
    const filter = yield select(selectFilter);
    const params = {};

    // build query params
    if (doFiltering && filter.id) {
      params.id = filter.id;
    } else {
      const controlZoneCode = yield select(selectActiveControlZone);

      if (controlZoneCode) {
        params.controlZoneCode = controlZoneCode;
      }

      if (doFiltering && filter.date) {
        const startMoment = moment(filter.date).startOf('day');
        params.startDateTime = startMoment.toISOString();
        params.endDateTime = startMoment.clone().endOf('day').toISOString();
      } else {
        const startMoment = moment().startOf('day');
        params.startDateTime = startMoment.toISOString();
      }
    }

    // fetch flight requests
    const { data: flightRequests } = yield call(
      atcAPI.get,
      '/flight-requests',
      { params }
    );

    // dispatch to store
    yield put({ type: FETCH_SUCCEEDED, payload: flightRequests });
  } catch (error) {
    yield put(setError(error));
  }
}
