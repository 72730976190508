import { createReducer } from 'airshare-web-utils/redux-helpers';

// cction types
const RESET_APPROVAL_ZONES = 'approval-zones/RESET_APPROVAL_ZONES';
const SET_APPROVAL_ZONE = 'approval-zones/SET_APPROVAL_ZONE';

// action creators
export const resetApprovalZones = () => ({ type: RESET_APPROVAL_ZONES });
export const setApprovalZone = (mapCode, selected) => ({
  type: SET_APPROVAL_ZONE,
  payload: { mapCode, selected },
});

// reducers
export const defaultApprovalZones = [
  { selected: true, mapCode: 'primary-noa', name: 'Primary NOAs' },
  { selected: true, mapCode: 'secondary-noa', name: 'Seconday NOAs' },
  { selected: false, mapCode: 'aez', name: 'Auto Approval Exclusion Zones' },
  { selected: false, mapCode: 'a2r', name: 'Auto Approve to 200ft' },
];

export const reducer = createReducer(
  {
    [RESET_APPROVAL_ZONES]: () => defaultApprovalZones,

    [SET_APPROVAL_ZONE]: (state, { payload }) => {
      const { mapCode, selected } = payload;
      const i = state.findIndex((f) => f.mapCode === mapCode);

      if (i === -1) {
        return state;
      }

      const az = state[i];

      return [...state.slice(0, i), { ...az, selected }, ...state.slice(i + 1)];
    },
  },
  defaultApprovalZones
);

// selectors
export const selectApprovalZones = (state) => state.approvalZones;
