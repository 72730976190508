import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { childrenPropType } from 'airshare-web-utils/prop-types';

import './form-field.scss';
import Label from '../label/label';

const FormField = ({
  children,
  className = '',
  disabled = false,
  error,
  label,
  notes,
  testid,
  validated = false,
  required = false,
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <div
      className={classNames(className, 'airshare-form-field', {
        'has-error': error,
        'has-focus': hasFocus,
        validated,
        disabled,
      })}
      data-testid={testid}
    >
      <label>
        {React.cloneElement(children, {
          onBlur: () => setHasFocus(false),
          onFocus: () => setHasFocus(true),
        })}
        <Label label={label} required={required} />
      </label>

      {error && <p className="error-message">{error}</p>}

      {notes && <p className="notes">{notes}</p>}
    </div>
  );
};

FormField.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: childrenPropType,
  label: childrenPropType,
  notes: childrenPropType,
  required: PropTypes.bool,
  validated: PropTypes.bool,
  testid: PropTypes.string,
};

FormField.defaultProps = {
  children: null,
  className: null,
  error: null,
  label: null,
  notes: null,
  required: false,
  disabled: false,
  validated: false,
  testid: 'form-field',
};

export default FormField;
