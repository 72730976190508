/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { PasswordStrength } from 'atc-web-shared/src/components/password-strength/PasswordStrength';

import connectToStore from 'airshare-web-utils/connect-to-redux';
import TextField from 'airshare-react-components/text-field';

import {
  selectIsSubmitting,
  selectForm,
  selectVisibleErrors,
  selectValidationMessage,
  updateForm,
  submitForm,
} from '~/state/change-password';

import { routePaths } from '~/lib/constants';

const ChangePasswordScreen = ({
  isSubmitting,
  form,
  errors,
  validationMessage,
  onChange,
  onSubmit,
}) => {
  const [isPasswordValid, onPasswordValidityChange] = useState(false);
  const [passwordSuggestion, onPasswordSuggestionChange] = useState('');
  const handlePasswordChange = (result, zxcvbnResult) => {
    onChange({ newPassword: result.password });
    onPasswordValidityChange(result.isValid);
    if (
      zxcvbnResult &&
      zxcvbnResult.feedback &&
      zxcvbnResult.feedback.warning
    ) {
      onPasswordSuggestionChange(zxcvbnResult.feedback.warning);
    } else onPasswordSuggestionChange('');
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      noValidate
      autoComplete="off"
      data-testid="atc-web:change-password-page"
    >
      <TextField
        label="Email"
        value={form.email}
        onChange={(value) => onChange({ email: value })}
        disabled={isSubmitting}
        error={errors.email}
        autoComplete="new-password"
        testid="atc-web:change-password-page:email-field"
      />

      <TextField
        label="Reset key"
        value={form.resetKey}
        onChange={(value) => onChange({ resetKey: value })}
        disabled={isSubmitting}
        error={errors.resetKey}
        autoComplete="new-password"
        testid="atc-web:change-password-page:reset-key-field"
      />

      <PasswordStrength
        onPasswordChange={handlePasswordChange}
        placeholder=""
        label="New Password"
        style={{ borderRadius: 5, fontSize: 12 }}
        data-testid="atc-web:change-password-page:new-password-field"
      />

      <TextField
        label="Confirm password"
        value={form.confirmPassword}
        onChange={(value) => onChange({ confirmPassword: value })}
        disabled={isSubmitting}
        type="password"
        error={errors.confirmPassword}
        autoComplete="new-password"
        testid="atc-web:change-password-page:confirm-password-field"
      />

      {(validationMessage || passwordSuggestion) && (
        <div
          className="danger-callout"
          data-testid="atc-web:change-password-page:validation-message"
        >
          {validationMessage || passwordSuggestion}
        </div>
      )}

      <div className="buttons">
        <button
          className="btn-secondary"
          type="submit"
          disabled={isSubmitting || !isPasswordValid}
          data-testid="atc-web:change-password-page:submit-button"
        >
          {isSubmitting ? '...' : 'Send'}
        </button>

        <Link
          to={routePaths.logIn}
          data-testid="atc-web:change-password-page:login-link"
        >
          Log in
        </Link>
      </div>
    </form>
  );
};

export default connectToStore(
  ChangePasswordScreen,
  {
    isSubmitting: selectIsSubmitting,
    form: selectForm,
    errors: selectVisibleErrors,
    validationMessage: selectValidationMessage,
  },
  {
    onChange: updateForm,
    onSubmit: submitForm,
  }
);
