import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import FormField from '../form-field';

import './textarea-field.scss';

const TextArea = ({
  className,
  disabled,
  onChange,
  required,
  rows = 4,
  value,
  testid,
  ...formFieldProps
}) => (
  <FormField
    className={classnames('airshare-textarea-field', className)}
    required={required}
    disabled={disabled}
    {...formFieldProps}
  >
    <textarea
      className="input-control bordered-control"
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      spellCheck="false"
      value={value}
      rows={rows}
      data-testid={testid}
    />
  </FormField>
);

TextArea.propTypes = {
  ...FormField.propTypes,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  testid: PropTypes.string,
};

TextArea.defaultProps = {
  className: null,
  disabled: false,
  required: false,
  value: '',
  rows: 4,
  testid: 'textarea',
};

export default TextArea;
