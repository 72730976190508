import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import './flight-request-approval-screen.scss';

const Summary = ({ formRef, flightRequest: fr }) => (
  <div ref={formRef}>
    <h6 data-testid="atc-web:flight-request-approval-screen:where-section">
      Where
    </h6>
    <dl>
      <dt data-testid="atc-web:flight-request-approval-screen:where-section:control-zone">
        Control Zone:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:where-section:control-zone-value">
        {fr.controlZoneName}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:where-section:street-address">
        Street Address:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:where-section:street-address-value">
        {fr.streetAddress}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:where-section:operation-radius">
        Radius of Operation:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:where-section:operation-radius-value">
        {fr.radiusMetersStr}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:where-section:operation-area">
        Operating Area:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:where-section:operation-value">
        {fr.operatingArea}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:where-section:max-altitude">
        Max. Altitude AGL (ft):
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:where-section:max-altitude-value">
        {fr.maxAltitude}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:where-section:launch-elevation">
        Launch Elevation MSL (ft):
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:where-section:launch-elevation-value">
        {fr.launchElevation}
      </dd>
    </dl>
    <h6 data-testid="atc-web:flight-request-approval-screen:when-section">
      When
    </h6>
    <dl>
      <dt data-testid="atc-web:flight-request-approval-screen:when-section:start">
        Start:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:when-section:start-value">
        {moment(fr.startDateTime).format('HH:mm L')}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:when-section:finish">
        Finish:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:when-section:finish-value">
        {moment(fr.endDateTime).format('HH:mm L')}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:when-section:duration">
        Duration (hh:mm):
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:when-section:duration-value">
        {fr.duration}
      </dd>
    </dl>

    <h6 data-testid="atc-web:flight-request-approval-screen:what-section">
      What
    </h6>
    <dl>
      <dt data-testid="atc-web:flight-request-approval-screen:what-section:vehicle-type">
        Vehicle Type:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:what-section:vehicle-type-value">
        {fr.vehicleType}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:what-section:vehicle-weight">
        Vehicle Weight:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:what-section:vehicle-weight-value">
        {fr.vehicleWeight}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:what-section:measure-altitude">
        Measure Altitude:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:what-section:measure-altitude-value">
        {fr.procedureToMeasureAltitude}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:what-section:transponder">
        Transponder:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:what-section:transponder-value">
        {fr.vehicleTransmitter}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:what-section:vfh-radio">
        VHF Radio Contact:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:what-section:vfh-radio-value">
        {fr.vehicleHasVhfRadioContact}
      </dd>
    </dl>

    <h6 data-testid="atc-web:flight-request-approval-screen:how-section">
      How
    </h6>
    <dl>
      <dt data-testid="atc-web:flight-request-approval-screen:how-section:rule-part">
        Rule Part:
      </dt>
      <dd>
        <span
          className={`text-bubble ${
            fr.operatingRule === 'Part 102' ? 'warning-color' : 'disabled-color'
          }`}
          data-testid="atc-web:flight-request-approval-screen:how-section:rule-part-value"
        >
          {fr.operatingRule}
        </span>
        {fr.certificationNumber && <span>, ref: {fr.certificationNumber}</span>}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:how-section:shielded">
        Shielded Operation:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:how-section:shielded-value">
        {fr.isShielded}
      </dd>
    </dl>

    <h6 data-testid="atc-web:flight-request-approval-screen:who-section">
      Who
    </h6>
    <dl>
      <dt data-testid="atc-web:flight-request-approval-screen:who-section:business-name">
        Business Name:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:who-section:business-name-value">
        {fr.businessName}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:who-section:contact">
        Account Contact:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:who-section:contact-name">
        {fr?.accountName && fr?.accountMobile
          ? `${fr.accountName}, ${fr.accountMobile}`
          : '-'}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:who-section:pilot">
        Pilot:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:who-section:pilot-name">
        {fr.pilotName}, {fr.pilotMobile}
      </dd>
    </dl>

    <h6 data-testid="atc-web:flight-request-approval-screen:add-info-section">
      Additional Information
    </h6>
    <dl>
      <dt data-testid="atc-web:flight-request-approval-screen:add-info-section:flight-name">
        Name:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:add-info-section:flight-name-value">
        {fr.flightName || ''}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:add-info-section:purpose">
        Purpose:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:add-info-section:purpose-value">
        {fr.flightPurpose}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:add-info-section:filiming">
        Filming:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:add-info-section:filiming-value">
        {fr.isFilming}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:add-info-section:emergency-procd">
        Emergency Procedure:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:add-info-section:emergency-procd-value">
        {fr.emergencyProcedure}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:add-info-section:other-info">
        Other Info:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:add-info-section:other-info-value">
        {fr.otherInfo}
      </dd>
    </dl>

    <h6 data-testid="atc-web:flight-request-approval-screen:decision-section">
      Decision
    </h6>
    <dl>
      <dt data-testid="atc-web:flight-request-approval-screen:decision-section:id">
        ID:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:decision-section:id-value">
        {fr.flightId}
      </dd>
      <dt data-testid="atc-web:flight-request-approval-screen:status">
        Status of Request:
      </dt>
      <dd data-testid="atc-web:flight-request-approval-screen:status-value">
        {fr.statusLabel}
      </dd>
      {fr.decisionControlZone && (
        <>
          <dt data-testid="atc-web:flight-request-approval-screen:decision-section:tower">
            Tower:
          </dt>
          <dd data-testid="atc-web:flight-request-approval-screen:decision-section:tower-value">
            {fr.decisionControlZone}
          </dd>
        </>
      )}
      {fr.decisionControlZonePhone && (
        <>
          <dt data-testid="atc-web:flight-request-approval-screen:decision-section:tower-phone">
            Tower Phone:
          </dt>
          <dd data-testid="atc-web:flight-request-approval-screen:decision-section:tower-phone-value">
            {fr.decisionControlZonePhone}
          </dd>
        </>
      )}
      {fr.decisionMakerName && (
        <>
          <dt data-testid="atc-web:flight-request-approval-screen:decision-section:decision-marker">
            Decision Maker:
          </dt>
          <dd data-testid="atc-web:flight-request-approval-screen:decision-section:decision-marker-value">
            {fr.decisionMakerName}
          </dd>
        </>
      )}
      {fr.decisionMakerName && (
        <>
          <dt data-testid="atc-web:flight-request-approval-screen:decision-section:decision-date">
            Decision Date and Time:
          </dt>
          <dd data-testid="atc-web:flight-request-approval-screen:decision-section:decision-date-value">
            {moment(fr.decisionDateTime).format('HH:mm L')}
          </dd>
        </>
      )}
    </dl>

    {fr.atcNotes && fr.atcNotes.length > 0 && (
      <>
        <h6 data-testid="atc-web:flight-request-approval-screen:atc-notes-section">
          ATC Notes
        </h6>
        <table role="table" className="striped-table">
          <thead>
            <tr>
              <th data-testid="atc-web:flight-request-approval-screen:atc-notes-section:when">
                When
              </th>
              <th data-testid="atc-web:flight-request-approval-screen:atc-notes-section:who">
                Who
              </th>
              <th data-testid="atc-web:flight-request-approval-screen:atc-notes-section:note">
                Note
              </th>
            </tr>
          </thead>
          <tbody>
            {fr.atcNotes.map((n) => (
              <tr key={n.updatedAt}>
                <td data-testid="atc-web:flight-request-approval-screen:atc-notes-section:when-value">
                  {moment(n.updatedAt).format('HH:mm L')}
                </td>
                <td data-testid="atc-web:flight-request-approval-screen:atc-notes-section:who-value">
                  {n.authorName}
                </td>
                <td data-testid="atc-web:flight-request-approval-screen:atc-notes-section:note-text">
                  {n.text}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )}
  </div>
);

const ATCNote = PropTypes.shape({
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
});

Summary.propTypes = {
  formRef: PropTypes.shape({ current: PropTypes.elementType }).isRequired,
  flightRequest: PropTypes.shape({
    // Where
    controlZoneName: PropTypes.string.isRequired,
    streetAddress: PropTypes.string.isRequired,
    radiusMetersStr: PropTypes.string.isRequired,
    operatingArea: PropTypes.string, // descriptionOfOperatingArea
    maxAltitude: PropTypes.number,
    launchElevation: PropTypes.number,
    // When
    startDateTime: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    // What
    vehicleType: PropTypes.string,
    vehicleWeight: PropTypes.string, // uavWeight.code e.g. '<15', '15 to 25', '>25'
    procedureToMeasureAltitude: PropTypes.string,
    vehicleTransmitter: PropTypes.string,
    vehicleHasVhfRadioContact: PropTypes.string,
    // How
    operatingRule: PropTypes.oneOf(['Part 101', 'Part 102']),
    certificationNumber: PropTypes.string,
    isShielded: PropTypes.string,
    // Who
    businessName: PropTypes.string,
    accountName: PropTypes.string,
    accountMobile: PropTypes.string,
    pilotName: PropTypes.string.isRequired,
    pilotMobile: PropTypes.string.isRequired,
    // AdditionalInformation
    flightName: PropTypes.string,
    flightPurpose: PropTypes.string,
    isFilming: PropTypes.bool,
    emergencyProcedure: PropTypes.string,
    otherInfo: PropTypes.string,
    // Decision
    flightId: PropTypes.number.isRequired,
    statusLabel: PropTypes.string.isRequired,
    decisionControlZone: PropTypes.string,
    decisionControlZonePhone: PropTypes.string,
    decisionMakerPhone: PropTypes.string,
    decisionDateTime: PropTypes.string,
    decisionMakerName: PropTypes.string,
    atcNotes: PropTypes.arrayOf(ATCNote).isRequired,
  }).isRequired,
};

export default Summary;
