import { createSelector } from 'reselect';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import { combineReducers } from 'redux';
import cloneDeep from 'lodash/cloneDeep';

import { call, put, takeLatest, select } from 'redux-saga/effects';

import { atcAPI } from '~/lib/api';
import { setError, SET_ERROR } from './session';

// action types

const POPULATE_FORM = 'control-zone-config-form/POPULATE_FORM';
const UPDATE_FORM = 'control-zone-config-form/UPDATE_FORM';
const SUBMIT_FORM = 'control-zone-config-form/SUBMIT_FORM';
export const SUBMIT_SUCCEEDED = 'control-zone-config-form/SUBMIT_SUCCEEDED';
const SET_VALIDATION_ERROR = 'control-zone-config-form/SET_VALIDATION_ERROR';
export const CANCEL_EDITING = 'control-zone-config-form/CANCEL_EDITING';

// action creators
export const populateForm = (czConfig) => ({
  type: POPULATE_FORM,
  payload: czConfig,
});
export const updateForm = (patch) => ({ type: UPDATE_FORM, payload: patch });
export const submitForm = (formHTML) => ({
  type: SUBMIT_FORM,
  payload: formHTML,
});
export const cancelEditing = () => ({ type: CANCEL_EDITING });

// reducers
const formReducer = createReducer({
  [POPULATE_FORM]: (_, { payload }) => cloneDeep(payload),
  [UPDATE_FORM]: (state, { payload }) => ({ ...state, ...payload }),
  [SUBMIT_SUCCEEDED]: () => null,
  [CANCEL_EDITING]: () => null,
});

const isSubmittingReducer = createReducer(
  {
    [POPULATE_FORM]: () => false,
    [SUBMIT_FORM]: () => true,
    [SUBMIT_SUCCEEDED]: () => false,
    [SET_VALIDATION_ERROR]: () => false,
    [SET_ERROR]: () => false,
    [CANCEL_EDITING]: () => false,
  },
  false
);

const validationErrorReducer = createReducer({
  [POPULATE_FORM]: () => null,
  [SUBMIT_SUCCEEDED]: () => null,
  [SET_VALIDATION_ERROR]: (_, { payload }) => payload,
  [CANCEL_EDITING]: () => null,
});

export const reducer = combineReducers({
  form: formReducer,
  isSubmitting: isSubmittingReducer,
  validationError: validationErrorReducer,
});

// selectors
export const getLocalState = (state) => state.controlZoneConfigForm;

export const selectForm = createSelector(
  [getLocalState],
  (state) => state.form
);

export const selectIsSubmitting = createSelector(
  [getLocalState],
  (state) => state.isSubmitting
);

export const selectValidationError = createSelector(
  [getLocalState],
  (state) => state.validationError
);

// sagas
export function* saga() {
  yield takeLatest(SUBMIT_FORM, onSubmitForm);
}

function* onSubmitForm() {
  try {
    const form = yield select(selectForm);
    yield call(
      atcAPI.put,
      `/control-zone-configs/${form.controlZoneCode}`,
      form
    );
    yield put({ type: SUBMIT_SUCCEEDED });
  } catch (error) {
    const { response } = error;

    if (
      response &&
      response.status === 400 &&
      response.data &&
      response.data.message
    ) {
      yield put({
        type: SET_VALIDATION_ERROR,
        payload: response.data.message,
      });
    } else {
      yield put(setError(error));
    }
  }
}
