/* eslint-disable no-underscore-dangle, max-len */
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import history from 'airshare-web-utils/history';

import rootSaga from './root-saga';
import { reducer as session } from './session';
import { reducer as logIn } from './log-in';
import { reducer as resetPassword } from './reset-password';
import { reducer as changePassword } from './change-password';
import { reducer as flightRequests } from './flight-requests';
import { reducer as flightRequestApproval } from './flight-request-approval';
import { reducer as approvalZones } from './approval-zones';
import { reducer as controlZones } from './control-zones';
import { reducer as visualFlightRequests } from './visual-flight-requests';
import { reducer as controlZoneConfigs } from './control-zone-configs';
import { reducer as controlZoneConfigForm } from './control-zone-config-form';

// Enable redux devtools in development mode
let composeEnhancers;
const sagaConfig = {};

if (window.env.ENABLE_DEVTOOLS) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

// Configure middleware
const sagaMiddleware = createSagaMiddleware(sagaConfig);
const allMiddleware = applyMiddleware(
  routerMiddleware(history),
  sagaMiddleware
);
const rootEnhancer = composeEnhancers(allMiddleware);

// Create store
const rootReducer = combineReducers({
  session,
  logIn,
  resetPassword,
  changePassword,
  flightRequests,
  flightRequestApproval,
  controlZones,
  approvalZones,
  visualFlightRequests,
  controlZoneConfigs,
  controlZoneConfigForm,
  router: connectRouter(history),
});

const store = createStore(rootReducer, rootEnhancer);

export default store;

// Sagas must be started after other
// dependencies have been initialized
// by explicitly calling this from the
// code after initialization.
export const startSagas = () => {
  sagaMiddleware.run(rootSaga);
};
