import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { childrenPropType } from 'airshare-web-utils/prop-types';

import './tab-bar.scss';

const TabBar = ({ children, className, boxed }) => (
  <div className={classnames(className, 'airshare-tab-bar', { boxed })}>
    {children}
  </div>
);

TabBar.propTypes = {
  children: childrenPropType,
  className: PropTypes.string,
  boxed: PropTypes.bool,
};

TabBar.defaultProps = {
  children: null,
  className: null,
  boxed: false,
};

export default TabBar;
