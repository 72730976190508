import { useEffect, useRef } from 'react';
import { Loader } from 'google-maps';

const loaderOptions = {
  version: '3.36',
  language: 'en',
  libraries: ['geometry', 'drawing', 'places'],
};

const loader = new Loader(window.env.GOOGLE_MAPS_API_KEY, loaderOptions);
const loaderPromise = loader.load();

export const useMap = (options) =>
  loaderPromise.then((google) => {
    const mapElement = document.createElement('div');
    mapElement.classList.add('airshare-map');
    const map = new google.maps.Map(mapElement, options);
    return { map, google };
  });

export const useMapRef = (map) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (map) {
      mapRef.current.appendChild(map.getDiv());
    }
  }, [map]);

  return mapRef;
};
