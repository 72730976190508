/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import connectToStore from 'airshare-web-utils/connect-to-redux';
import TextField from 'airshare-react-components/text-field';
import { Recaptcha } from 'common-ui-components';

import { routePaths } from '~/lib/constants';

import {
  selectForm,
  selectVisibleErrors,
  selectIsSubmitting,
  selectValidationMessage,
  updateForm,
  submitForm,
} from '~/state/log-in';

const LogInScreen = ({
  form,
  errors,
  isSubmitting,
  validationMessage,
  onSubmit,
  onChange,
}) => {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    // Reset the recaptcha on login failure
    if (
      validationMessage &&
      validationMessage.length > 0 &&
      recaptchaRef.current
    ) {
      console.log('RESETTING');

      recaptchaRef.current.reset();
    }
  }, [validationMessage]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (recaptchaToken) {
          form.recaptchaToken = recaptchaToken;
          onSubmit();
        }
      }}
      noValidate
      autoComplete="off"
      className="log-in-screen"
      data-testid="atc-web:login-page"
    >
      <TextField
        label="User name"
        value={form.userName}
        onChange={(value) => onChange({ userName: value })}
        disabled={isSubmitting}
        error={errors.userName}
        testid="atc-web:login-page:username-field"
      />

      <TextField
        type="password"
        label="Password"
        value={form.password}
        onChange={(value) => onChange({ password: value })}
        disabled={isSubmitting}
        error={errors.password}
        testid="atc-web:login-page:password-field"
      />

      {validationMessage && (
        <div
          className="danger-callout"
          data-testid="atc-web:login-page:validation-message"
        >
          {validationMessage}
        </div>
      )}

      <div
        style={{ padding: '16px 28px' }}
        data-testid="atc-web:login-page:recaptcha"
      >
        <Recaptcha ref={recaptchaRef} setRecaptchaToken={setRecaptchaToken} />
      </div>

      <div className="buttons">
        <button
          className="btn-secondary"
          type="submit"
          disabled={isSubmitting || !recaptchaToken}
          data-testid="atc-web:login-page:submit-button"
        >
          {isSubmitting ? '...' : 'Sign in'}
        </button>

        <Link
          to={routePaths.resetPassword}
          data-testid="atc-web:login-page:forgot-password-link"
        >
          Forgot Password
        </Link>
      </div>
    </form>
  );
};

export default connectToStore(
  LogInScreen,
  {
    form: selectForm,
    errors: selectVisibleErrors,
    isSubmitting: selectIsSubmitting,
    validationMessage: selectValidationMessage,
  },
  {
    onSubmit: submitForm,
    onChange: updateForm,
  }
);
