import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './overlay.scss';

const Overlay = ({ className, isVisible, onClick }) => (
  <div
    className={classnames(className, 'airshare-overlay', {
      'is-visible': isVisible,
    })}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  />
);

const noop = () => {};

Overlay.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

Overlay.defaultProps = {
  className: null,
  onClick: noop,
};

export default Overlay;
