/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import connectToStore from 'airshare-web-utils/connect-to-redux';
import Checkbox from 'airshare-react-components/checkbox';

import {
  selectApprovalZones,
  resetApprovalZones,
  setApprovalZone,
} from '~/state/approval-zones';

import useHooks from './hooks';
import './map.scss';

const Map = ({
  center,
  zoom,
  flightRequests,
  approvalZones,
  onInit,
  onSetApprovalZone,
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      onInit();
    } catch (e) {
      setError(e);
    }
  }, []);

  const mapRef = useHooks({
    center,
    zoom,
    flightRequests,
    approvalZones,
  });

  if (error) {
    return <div>Map Error</div>;
  }

  return (
    <div className="argus-atc-web-map" data-testid="atc-web:web-map">
      <div className="map-checkboxes">
        {approvalZones.map((az) => (
          <Checkbox
            key={az.mapCode}
            value={az.mapCode}
            label={az.name}
            checked={az.selected}
            onChange={onSetApprovalZone}
          />
        ))}
      </div>
      <div
        className="map-screen"
        ref={mapRef}
        data-testid="atc-web:web-map-screen"
      />
    </div>
  );
};

export default connectToStore(
  Map,
  {
    approvalZones: selectApprovalZones,
  },
  {
    onInit: resetApprovalZones,
    onSetApprovalZone: setApprovalZone,
  }
);
