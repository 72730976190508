import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './label.scss';

const Label = ({ className, label, required }) =>
  label && (
    <div className={classNames(className, 'airshare-label')}>
      <span>{label}</span>
      {required && <span>*</span>}
    </div>
  );

Label.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

Label.defaultProps = {
  className: null,
  label: null,
  required: false,
};

export default Label;
