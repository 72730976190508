import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import FormField from '../form-field';

const TextField = ({
  className,
  disabled = false,
  onChange,
  placeholder = '',
  required = false,
  type,
  value,
  min,
  step,
  autoComplete,
  testid,
  ...formFieldProps
}) => (
  <FormField
    className={classnames('airshare-text-field', className)}
    required={required}
    disabled={disabled}
    {...formFieldProps}
  >
    <input
      className="input-control bordered-control"
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      required={required}
      spellCheck="false"
      type={type}
      value={value}
      step={step}
      min={min}
      autoComplete={autoComplete}
      data-testid={testid}
    />
  </FormField>
);

TextField.propTypes = {
  ...FormField.propTypes,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  testid: PropTypes.string,
};

TextField.defaultProps = {
  className: null,
  disabled: false,
  placeholder: '',
  required: false,
  type: 'text',
  value: '',
  testid: 'textfield',
};

export default TextField;
