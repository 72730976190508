import {
  take,
  fork,
  put,
  cancel,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { push } from 'connected-react-router';

import { LOG_OUT } from 'airshare-web-utils/redux-helpers';

import { routePaths } from '~/lib/constants';

import { saga as controlZones } from './control-zones';

import {
  fetch as fetchFlightRequests,
  saga as flightRequests,
} from './flight-requests';

import {
  fetch as fetchVisualFlightRequests,
  saga as visualFlightRequests,
} from './visual-flight-requests';

import {
  saga as flightRequestApproval,
  SUBMIT_SUCCEEDED as FLIGHT_REQUEST_SUBMIT_SUCCESS,
} from './flight-request-approval';

import { saga as session, setProfile, SET_PROFILE } from './session';

import { saga as login, SUBMIT_SUCCEEDED as LOGIN_SUCCESS } from './log-in';

import {
  saga as resetPassword,
  SUBMIT_SUCCEEDED as RESET_PASSWORD_SUCCESS,
} from './reset-password';

import {
  saga as changePassword,
  SUBMIT_SUCCEEDED as CHANGE_PASSWORD_SUCCESS,
} from './change-password';

import {
  saga as controlZoneConfigs,
  fetch as fetchControlZoneConfigs,
  selectList as selectControlZoneConfigs,
  FOCUS_ONE as FOCUS_ON_CONTROL_ZONE_CONFIG,
  FETCH_SUCCEEDED as CONTROL_ZONE_CONFIGS_FETCH_SUCCESS,
} from './control-zone-configs';

import {
  saga as controlZoneConfigForm,
  populateForm as populateCzConfigForm,
  SUBMIT_SUCCEEDED as CONTROL_ZONE_CONFIG_SUBMIT_SUCCESS,
  CANCEL_EDITING as CONTROL_ZONE_CONFIG_CANCEL_EDITING,
} from './control-zone-config-form';

export default function* saga() {
  // start sagas that run always
  yield fork(session);

  // main flow
  while (true) {
    // start sagas that run when the user is logged out
    let tasks = [];
    tasks.push(yield fork(login));
    tasks.push(yield fork(resetPassword));
    tasks.push(yield fork(changePassword));
    tasks.push(yield takeLatest(LOGIN_SUCCESS, onLoginSucceeded));
    tasks.push(
      yield takeLatest(RESET_PASSWORD_SUCCESS, onResetPasswordSuccess)
    );
    tasks.push(
      yield takeLatest(CHANGE_PASSWORD_SUCCESS, onChangePasswordSuccess)
    );

    // wait for the SET_PROFILE action to be dispatched
    const { payload } = yield take(SET_PROFILE);
    const { profile } = payload;

    // user has logged in so cancel tasks
    yield cancel(tasks);

    // start sagas that run when the user is logged
    tasks = [];

    // module sagas
    tasks.push(yield fork(controlZones, profile));
    tasks.push(yield fork(flightRequests));
    tasks.push(yield fork(flightRequestApproval));
    tasks.push(yield fork(visualFlightRequests));
    tasks.push(yield fork(controlZoneConfigs));
    tasks.push(yield fork(controlZoneConfigForm));

    // link sagas...listen for dispatched actions
    // allowing us to communicate between sagas
    // cleanly e.g refresh flight requests list
    // after approving a flight request
    tasks.push(
      yield takeLatest(
        FLIGHT_REQUEST_SUBMIT_SUCCESS,
        onFlightRequestSubmitSuccess
      )
    );

    tasks.push(
      yield takeLatest(FOCUS_ON_CONTROL_ZONE_CONFIG, onControlZoneConfigFocused)
    );

    tasks.push(
      yield takeLatest(
        CONTROL_ZONE_CONFIGS_FETCH_SUCCESS,
        onControlZoneConfigsFetched
      )
    );

    tasks.push(
      yield takeLatest(
        CONTROL_ZONE_CONFIG_SUBMIT_SUCCESS,
        onControlZoneConfigSubmitSuccess
      )
    );

    tasks.push(
      yield takeLatest(
        CONTROL_ZONE_CONFIG_CANCEL_EDITING,
        onFinishedEditingControlZoneConfig
      )
    );

    // wait for LOG_OUT action to be dispatched
    yield take(LOG_OUT);

    // user has logged out so cancel tasks
    yield cancel(tasks);

    // navigate to the root
    yield put(push('/'));
  }
}

// handle actions from child sagas...allows us to
// communicate between sagas cleanly e.g refresh
// flight requests list after approving a flight
// request
function* onLoginSucceeded({ payload }) {
  yield put(setProfile(payload));
}

function* onResetPasswordSuccess() {
  yield put(push(routePaths.changePassword));
}

function* onChangePasswordSuccess() {
  yield put(push(routePaths.logIn));
}

function* onFlightRequestSubmitSuccess() {
  yield put(fetchFlightRequests());
  yield put(fetchVisualFlightRequests());
  yield put(push(routePaths.flightRequests));
}

function* onControlZoneConfigFocused({ payload }) {
  yield put(populateCzConfigForm(payload));
}

function* onControlZoneConfigsFetched({ payload }) {
  if (payload && payload.length === 1) {
    const [czConfig] = payload;
    yield put(populateCzConfigForm(czConfig));
  }
}

function* onControlZoneConfigSubmitSuccess() {
  yield put(fetchControlZoneConfigs());
  yield fork(onFinishedEditingControlZoneConfig);
}

function* onFinishedEditingControlZoneConfig() {
  const czConfigs = yield select(selectControlZoneConfigs);

  if (czConfigs.length === 1) {
    yield put(push(routePaths.flightRequests));
    const [czConfig] = czConfigs;
    yield put(populateCzConfigForm(czConfig));
  }
}
