import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default () => {
  const { location } = useSelector((s) => s.router);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
};
