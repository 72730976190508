import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import FormField from '../form-field';

import './toggle.scss';

const Toggle = ({
  on,
  className,
  disabled,
  label,
  onChange,
  value,
  ...formFieldProps
}) => (
  <FormField
    className={classnames(className, 'airshare-toggle')}
    disabled={disabled}
    label={label}
    {...formFieldProps}
    testid="airshare-toggle"
  >
    <InputControl
      on={on}
      disabled={disabled}
      onChange={onChange}
      type="checkbox"
      value={value}
    />
  </FormField>
);

const InputControl = ({ on, disabled, onBlur, onChange, onFocus, value }) => (
  <>
    <input
      checked={on}
      disabled={disabled}
      onBlur={onBlur}
      onChange={(e) => onChange(value, e.target.checked)}
      onFocus={onFocus}
      type="checkbox"
      value={value}
    />
    <span className="toggle-background bordered-control ignore-focus">
      <span className="toggle-circle" />
    </span>
  </>
);

InputControl.propTypes = {
  on: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  value: PropTypes.any, // eslint-disable-line
};

InputControl.defaultProps = {
  onBlur: () => {},
  onFocus: () => {},
  disabled: false,
};

Toggle.propTypes = {
  ...FormField.propTypes.propTypes,
  className: PropTypes.string,
};

Toggle.defaultProps = {
  className: null,
};

export default Toggle;
