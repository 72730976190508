import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from '../label/label';

import './checkbox.scss';

const Checkbox = ({
  checked,
  className,
  disabled,
  label,
  onBlur,
  onChange,
  onFocus,
  value,
}) => (
  <label
    className={classnames(className, 'airshare-checkbox')}
    data-testid={`atc-web:map:${value}-checkbox`}
  >
    <input
      checked={checked}
      disabled={disabled}
      onBlur={onBlur}
      onChange={(e) => onChange(value, e.target.checked)}
      onFocus={onFocus}
      type="checkbox"
      value={value}
    />
    <span className="checkbox-square bordered-control ignore-focus" />
    <Label label={label} />
  </label>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any, // eslint-disable-line
};

Checkbox.defaultProps = {
  className: null,
  checked: false,
  disabled: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  label: '',
};

export default Checkbox;
