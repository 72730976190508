/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { hot } from 'react-hot-loader';
import { loadReCaptcha } from 'react-recaptcha-google';

import { ErrorBoundary } from 'common-ui-components';
import connectToStore from 'airshare-web-utils/connect-to-redux';
import history from 'airshare-web-utils/history';

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
} from 'airshare-react-components/dialog';

import { selectUserProfile, selectError, clearError } from '~/state/session';

import useScrollToTop from '~/lib/scroll-to-top-hook';

import PrivateLayout from '../private-layout/private-layout';
import AuthLayout from '../auth-layout/auth-layout';

import './app.scss';

// Root component must be a class component for HMR to work.
const App = ({ userProfile, error, onClearError }) => {
  useScrollToTop();
  useEffect(() => {
    loadReCaptcha();
  }, []);

  return (
    <ConnectedRouter history={history}>
      <>
        <ErrorBoundary>
          {error && <ErrorModal error={error} onClearError={onClearError} />}
          {userProfile ? <PrivateLayout /> : <AuthLayout />}
        </ErrorBoundary>
      </>
    </ConnectedRouter>
  );
};

const ErrorModal = ({ error, onClearError }) => (
  <Dialog
    className="error-dialog"
    isOpen={error !== null}
    onClose={onClearError}
  >
    <DialogTitle>
      <h4>Unexpected Error</h4>
    </DialogTitle>
    <DialogContent>
      <p>
        An unexpected error occurred. Please report this to AirShare so we can
        fix it. After closing this dialog you might need to refresh the page.
      </p>
      {error && error.message && (
        <p className="error-message">{error.message}</p>
      )}
    </DialogContent>
    <DialogFooter>
      <button
        type="button"
        onClick={onClearError}
        className="btn-primary btn-s"
      >
        Close
      </button>
    </DialogFooter>
  </Dialog>
);

const AppContainer = connectToStore(
  App,
  {
    userProfile: selectUserProfile,
    error: selectError,
  },
  {
    onClearError: clearError,
  }
);

export default hot(module)(AppContainer);
