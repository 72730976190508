import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { childrenPropType } from 'airshare-web-utils/prop-types';

import './tab-panel.scss';

const TabPanel = ({ children, className, boxed }) => (
  <div className={classnames(className, 'airshare-tab-panel', { boxed })}>
    {children}
  </div>
);

TabPanel.propTypes = {
  children: childrenPropType,
  className: PropTypes.string,
  boxed: PropTypes.bool,
};

TabPanel.defaultProps = {
  children: null,
  className: null,
  boxed: false,
};

export default TabPanel;
