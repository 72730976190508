import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker/DayPicker';
import classnames from 'classnames';
import moment from 'moment';

import './calendar.scss';

const Calendar = ({ className, initialMonth, onChange, value }) => (
  <DayPicker
    captionElement={MonthCaption}
    className={classnames('airshare-calendar', className)}
    initialMonth={initialMonth || new Date()}
    onDayClick={onChange}
    selectedDays={value}
    showOutsideDays
  />
);

export const MonthCaption = ({ date }) => (
  <div className="month-caption">
    {moment(date).format('MMM YYYY').toUpperCase()}
  </div>
);

MonthCaption.propTypes = {
  date: PropTypes.instanceOf(Date),
};

MonthCaption.defaultProps = {
  date: null,
};

Calendar.propTypes = {
  className: PropTypes.string,
  initialMonth: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
};

Calendar.defaultProps = {
  className: null,
  initialMonth: null,
  onChange: () => {},
  value: null,
};

export default Calendar;
