import { createSelector } from 'reselect';
import { createReducer } from 'airshare-web-utils/redux-helpers';
import { combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';

import { call, put, takeLatest, select } from 'redux-saga/effects';

import { atcAPI } from '~/lib/api';

import { selectUserControlZones, setError, SET_ERROR } from './session';

// action types
const FETCH = 'flight-request/FETCH';
const FETCH_SUCCEEDED = 'flight-request/FETCH_SUCCEEDED';
const UPDATE_FORM = 'flight-request/UPDATE_FORM';
const SUBMIT_FORM = 'flight-request/SUBMIT_FORM';
export const SUBMIT_SUCCEEDED = 'flight-request/SUBMIT_SUCCEEDED';
const SET_VALIDATION_ERROR = 'flight-request/SET_VALIDATION_ERROR';
const VIEW_TAB = 'flight-request/VIEW_TAB';

// action creators
export const fetch = (id) => ({ type: FETCH, payload: id });
const fetchSucceeded = (code) => ({ type: FETCH_SUCCEEDED, payload: code });
export const updateForm = (patch) => ({ type: UPDATE_FORM, payload: patch });
export const submitForm = (formHTML) => ({
  type: SUBMIT_FORM,
  payload: formHTML,
});
export const viewTab = (tab) => ({ type: VIEW_TAB, payload: tab });

// reducers
const flightRequestReducer = createReducer({
  [FETCH]: () => null,
  [FETCH_SUCCEEDED]: (_, { payload }) =>
    (payload && payload.flightRequest) || null,
});

const rawFlightRequestReducer = createReducer({
  [FETCH]: () => null,
  [FETCH_SUCCEEDED]: (_, { payload }) =>
    (payload && payload.rawFlightRequest) || null,
});

const activeTabReducer = createReducer(
  {
    [VIEW_TAB]: (_, { payload }) => payload,
  },
  'summary'
);

const defaultForm = {
  atcNote: '',
  operatorNote: '',
  newStatus: '',
};

const formReducer = createReducer(
  {
    [FETCH_SUCCEEDED]: () => ({ ...defaultForm }),
    [UPDATE_FORM]: (state, { payload }) => ({ ...state, ...payload }),
  },
  defaultForm
);

const isLoadingReducer = createReducer(
  {
    [FETCH]: () => true,
    [FETCH_SUCCEEDED]: () => false,
    [SET_ERROR]: () => false,
  },
  true
);

const isSubmittingReducer = createReducer(
  {
    [FETCH]: () => false,
    [FETCH_SUCCEEDED]: () => false,
    [SUBMIT_FORM]: () => true,
    [SUBMIT_SUCCEEDED]: () => false,
    [SET_VALIDATION_ERROR]: () => false,
    [SET_ERROR]: () => false,
  },
  false
);

const validationErrorReducer = createReducer({
  [SET_VALIDATION_ERROR]: (_, { payload }) => payload,
  [FETCH]: () => null,
  [SUBMIT_FORM]: () => null,
});

export const reducer = combineReducers({
  flightRequest: flightRequestReducer,
  rawFlightRequest: rawFlightRequestReducer,
  form: formReducer,
  isLoading: isLoadingReducer,
  isSubmitting: isSubmittingReducer,
  activeTab: activeTabReducer,
  validationError: validationErrorReducer,
});

// selectors
export const getLocalState = (state) => state.flightRequestApproval;

export const selectFlightRequest = createSelector(
  [getLocalState],
  (state) => state.flightRequest
);

export const selectRawFlightRequest = createSelector(
  [getLocalState],
  (state) => state.rawFlightRequest
);

export const selectForm = createSelector(
  [getLocalState],
  (state) => state.form
);

export const selectIsLoading = createSelector(
  [getLocalState],
  (state) => state.isLoading
);

export const selectIsSubmitting = createSelector(
  [getLocalState],
  (state) => state.isSubmitting
);

export const selectValidationError = createSelector(
  [getLocalState],
  (state) => state.validationError
);

export const selectActiveTab = createSelector(
  [getLocalState],
  (state) => state.activeTab
);

export const selectCanAction = createSelector(
  [selectUserControlZones, selectFlightRequest],
  (controlZones, flightRequest) =>
    flightRequest &&
    controlZones &&
    controlZones.indexOf(flightRequest.controlZoneCode) > -1
);

// sagas
export function* saga() {
  yield takeLatest(FETCH, onFetch);
  yield takeLatest(SUBMIT_FORM, onSubmitForm);
}

function* onFetch({ payload: id }) {
  try {
    const { data } = yield call(atcAPI.get, `/flight-requests/${id}`);
    yield put(fetchSucceeded(data));
  } catch (error) {
    const { response } = error;

    if (response && response.status === 404) {
      yield put(fetchSucceeded(null));
    } else {
      yield put(setError(error));
    }
  }
}

function* onSubmitForm({ payload: formHTML }) {
  try {
    const form = yield select(selectForm);

    if (Object.values(form).some((v) => !isEmpty(v))) {
      const flightRequest = yield select(selectFlightRequest);

      const requestPayload = {
        id: flightRequest.id,
        ...form,
        formHTML,
      };

      yield call(atcAPI.post, '/submit-approval-form', requestPayload);
      yield put({ type: SUBMIT_SUCCEEDED });
    }
  } catch (error) {
    const { response } = error;

    if (
      response &&
      response.status === 400 &&
      response.data &&
      response.data.message
    ) {
      yield put({
        type: SET_VALIDATION_ERROR,
        payload: response.data,
      });
    } else {
      yield put(setError(error));
    }
  }
}
