/* eslint-disable react/prop-types */
import React from 'react';
import connectToStore from 'airshare-web-utils/connect-to-redux';

import { selectIsLoading, selectCount } from '~/state/control-zone-configs';

import { selectForm } from '~/state/control-zone-config-form';

import ControlZoneConfigForm from '../control-zone-config-form/control-zone-config-form';
import ControlZoneConfigs from '../control-zone-configs/control-zone-configs';

import './control-zone-configs-screen.scss';

function ControlZoneConfigsScreen({ isLoading, countControlZones, form }) {
  if (isLoading) {
    return <div className="loading-animation">Loading</div>;
  }

  return (
    <div
      className="control-zone-configs-screen"
      data-testid="atc-web:control-zone-config-screen"
    >
      {countControlZones > 1 && <ControlZoneConfigs />}
      {form && <ControlZoneConfigForm />}
    </div>
  );
}

export default connectToStore(
  ControlZoneConfigsScreen,
  {
    isLoading: selectIsLoading,
    countControlZones: selectCount,
    form: selectForm,
  },
  {}
);
