/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import connectToStore from 'airshare-web-utils/connect-to-redux';
import TextField from 'airshare-react-components/text-field';

import {
  selectIsSubmitting,
  selectForm,
  selectVisibleErrors,
  selectValidationMessage,
  updateForm,
  submitForm,
} from '~/state/reset-password';

import { routePaths } from '~/lib/constants';

const ResetPasswordScreen = ({
  isSubmitting,
  form,
  errors,
  validationMessage,
  onChange,
  onSubmit,
}) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit();
    }}
    noValidate
    autoComplete="off"
    data-testid="atc-web:reset-password-page"
  >
    <TextField
      label="Email"
      value={form.email}
      onChange={(value) => onChange({ email: value })}
      disabled={isSubmitting}
      error={errors.email}
      autoComplete="new-password"
      testid="atc-web:reset-password-page:email-field"
    />

    {validationMessage && (
      <div
        className="danger-callout"
        data-testid="atc-web:reset-password-page:validation-message"
      >
        {validationMessage}
      </div>
    )}

    <div className="buttons">
      <button
        className="btn-secondary"
        type="submit"
        disabled={isSubmitting}
        data-testid="atc-web:reset-password-page:submit-button"
      >
        {isSubmitting ? '...' : 'Send'}
      </button>

      <Link
        to={routePaths.logIn}
        data-testid="atc-web:reset-password-page:login-link"
      >
        Log In
      </Link>
    </div>
  </form>
);

export default connectToStore(
  ResetPasswordScreen,
  {
    isSubmitting: selectIsSubmitting,
    form: selectForm,
    errors: selectVisibleErrors,
    validationMessage: selectValidationMessage,
  },
  {
    onChange: updateForm,
    onSubmit: submitForm,
  }
);
