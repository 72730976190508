import { useState, useEffect } from 'react';

export const usePromise = (promise) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    promise.then((r) => {
      if (isSubscribed) {
        setResult(r);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  return result;
};
