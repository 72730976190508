import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { childrenPropType } from 'airshare-web-utils/prop-types';

import './app-bar.scss';

const AppBar = ({ children, className }) => (
  <div className={classnames(className, 'airshare-app-bar')}>{children}</div>
);

AppBar.propTypes = {
  children: childrenPropType.isRequired,
  className: PropTypes.string,
};

AppBar.defaultProps = {
  className: null,
};

export default AppBar;
