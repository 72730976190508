/* eslint-disable react/prop-types */
import React from 'react';

import connectToStore from 'airshare-web-utils/connect-to-redux';

import {
  selectFlightRequests,
  selectLastUpdated,
  selectDateProps,
  goToToday,
  goToNextDay,
  goToPreviousDay,
} from '~/state/visual-flight-requests';

import { selectCenterCoordinates } from '~/state/control-zones';

import Map from '../map/map';
import ControlZoneFilter from '../control-zone-filter';

import './visual-flight-requests-screen.scss';

const nzCoordinates = [174.7762, -41.2865]; // hard coded for now

const VisualFlightRequestsScreen = ({
  flightRequests,
  lastUpdated,
  date,
  centerCoordinates,
  onGoToToday,
  onGoToNextDay,
  onGoToPreviousDay,
}) => {
  const countFlights = (flightRequests || []).length;
  const { isToday, dateText } = date;

  return (
    <div
      className="visual-flight-requests-screen"
      data-testid="atc-web:visual-flights-request-screen"
    >
      <div className="filters">
        <ControlZoneFilter />

        <div
          className="airshare-label"
          data-testid="atc-web:visual-flights-request-screen:date-label"
        >
          Date
        </div>
        <div
          onClick={onGoToPreviousDay}
          className="select-prev-day"
          data-testid="atc-web:visual-flights-request-screen:select-prev-day"
        >
          {'<<'}
        </div>

        <div
          className="date-text"
          data-testid="atc-web:visual-flights-request-screen:date-text"
        >
          {dateText}
        </div>
        <div
          onClick={onGoToNextDay}
          className="select-next-day"
          data-testid="atc-web:visual-flights-request-screen:select-next-day"
        >
          {'>>'}
        </div>

        {!isToday && (
          <div
            onClick={onGoToToday}
            className="select-today"
            data-testid="atc-web:visual-flights-request-screen:select-today"
          >
            Today
          </div>
        )}

        <div
          className="count-flights"
          data-testid="atc-web:visual-flights-request-screen:flight-count"
        >
          {countFlights} flight{countFlights !== 1 && 's'}, updated{' '}
          {lastUpdated}
        </div>
      </div>
      {flightRequests ? (
        <Map
          zoom={centerCoordinates ? 12 : 6}
          center={centerCoordinates || nzCoordinates}
          flightRequests={flightRequests}
        />
      ) : (
        <div className="loading-animation">Loading</div>
      )}
    </div>
  );
};

export default connectToStore(
  VisualFlightRequestsScreen,
  {
    flightRequests: selectFlightRequests,
    lastUpdated: selectLastUpdated,
    date: selectDateProps,
    centerCoordinates: selectCenterCoordinates,
  },
  {
    onGoToToday: goToToday,
    onGoToNextDay: goToNextDay,
    onGoToPreviousDay: goToPreviousDay,
  }
);
