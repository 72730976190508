export default (google, map, urls) => {
  if (!map || !urls) {
    return;
  }

  if (map.data) {
    map.data.forEach((f) => map.data.remove(f));
  }

  urls.forEach((url) => {
    map.data.loadGeoJson(url);
  });
};
