import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Panel from 'airshare-react-components/panel';

import { routePaths } from '~/lib/constants';

import LogInScreen from '../log-in-screen';
import ResetPasswordScreen from '../reset-password-screen';
import ChangePasswordScreen from '../change-password-screen';
import './auth-layout.scss';

const AuthLayout = () => (
  <Panel boxed className="auth-layout" data-testid="atc-web:login-page:header">
    <h6>AIRWAYS AirShare Admin</h6>

    <Switch>
      <Route path={routePaths.logIn} component={LogInScreen} exact />
      <Route
        path={routePaths.resetPassword}
        component={ResetPasswordScreen}
        exact
      />
      <Route
        path={routePaths.changePassword}
        component={ChangePasswordScreen}
        exact
      />
      <Redirect to={routePaths.logIn} />
    </Switch>
  </Panel>
);

export default AuthLayout;
