/* eslint-disable react/prop-types */
import React from 'react';

import connectToStore from 'airshare-web-utils/connect-to-redux';
import Toggle from 'airshare-react-components/toggle';

import {
  selectForm,
  selectIsSubmitting,
  selectValidationError,
  submitForm,
  updateForm,
  cancelEditing,
} from '~/state/control-zone-config-form';

import './control-zone-config-form.scss';

function ControlZoneConfigForm({
  form,
  isSubmitting,
  validationError,
  onSubmit,
  onCancel,
  onChange,
}) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      noValidate
      autoComplete="off"
      className="control-zone-config-form"
      data-testid="atc-web:control-zone-config-form"
    >
      <h6 data-testid="atc-web:control-zone-config-form:name">{form.name}</h6>

      <Toggle
        label="Auto approval"
        on={form.autoApprovalEnabled}
        onChange={(val, on) => onChange({ autoApprovalEnabled: on })}
        testid="atc-web:control-zone-config-form:toggle-button"
      />

      {validationError && (
        <div
          className="danger-callout"
          data-testid="atc-web:control-zone-config-form:validation-error"
        >
          {validationError}
        </div>
      )}

      <div className="buttons">
        <button
          type="submit"
          disabled={isSubmitting}
          data-testid="atc-web:control-zone-config-form:save-button"
        >
          {isSubmitting ? '...' : 'Save'}
        </button>
        <button
          type="button"
          disabled={isSubmitting}
          onClick={onCancel}
          data-testid="atc-web:control-zone-config-form:cancel-button"
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default connectToStore(
  ControlZoneConfigForm,
  {
    form: selectForm,
    isSubmitting: selectIsSubmitting,
    validationError: selectValidationError,
  },
  {
    onSubmit: submitForm,
    onChange: updateForm,
    onCancel: cancelEditing,
  }
);
