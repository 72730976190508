import { useEffect, useRef, useCallback } from 'react';

import { usePromise } from 'airshare-web-utils/hooks';
import { useMap, useMapRef } from 'airshare-react-components/map/use-map';
import useGeoJson from 'airshare-react-components/map/use-geo-json';
import useZooming from 'airshare-react-components/map/use-zooming';
import useFullscreenButton from 'airshare-react-components/map/use-fullscreen-button';

import { defaultApprovalZones } from '~/state/approval-zones';
import { atcAPI } from '~/lib/api';

const mapPromise = (async () => {
  // create map
  const { google, map } = await useMap({ disableDefaultUI: true });

  // add some controls
  useZooming(google, map);
  useFullscreenButton(google, map);

  // load geojson map layers
  const { data: advisoryMapCodes } = await atcAPI.get('/advisory-map-codes');

  const urls = [
    ...defaultApprovalZones.map(
      ({ mapCode }) =>
        `${window.env.ARGUS_CONTENT_API_URL}/approval-zones/${mapCode}.geojson`
    ),
    ...advisoryMapCodes.map(
      (mapCode) =>
        `${window.env.ARGUS_CONTENT_API_URL}/advisories/layers/${mapCode}.geojson`
    ),
  ];

  useGeoJson(google, map, urls);

  // done
  return { google, map };
})();

export default ({ center, zoom, flightRequests, approvalZones }) => {
  // load the map singleton
  const { google, map } = usePromise(mapPromise) || {};
  const mapRef = useMapRef(map);

  // Change location when coordinates change
  useEffect(() => {
    if (map && zoom && center) {
      const [lng, lat] = center;
      map.setCenter({ lng, lat });
      map.setZoom(zoom);
    }
  }, [map, zoom, center]);

  // Draw flight paths
  const flightPaths = useRef();

  const clearFlightPaths = useCallback(() => {
    const paths = flightPaths.current;

    if (paths && paths.length > 0) {
      paths.forEach((fp) => fp.setMap(null));
      flightPaths.current = null;
    }
  });

  useEffect(() => {
    if (!map) {
      return () => {};
    }

    if (flightPaths.current) {
      clearFlightPaths();
    }

    if (flightRequests && flightRequests.length > 0) {
      flightPaths.current = flightRequests.map((fr) => {
        const shape = flightRequestToShape(google, fr);
        shape.setMap(map);
        return shape;
      });
    }

    return clearFlightPaths;
  }, [map, flightRequests]);

  // Hide and show map layers
  useEffect(() => {
    if (!map) {
      return;
    }

    const visibleCodes = approvalZones
      .filter((c) => c.selected)
      .map((c) => c.mapCode);

    const styleProps = [
      'mapCode',
      'strokeColor',
      'strokeOpacity',
      'strokeWeight',
      'fillColor',
      'fillOpacity',
    ];

    map.data.setStyle((feature) => {
      let props = feature.getProperty('props');

      if (!props) {
        props = styleProps.reduce(
          (acc, prop) => ({ ...acc, [prop]: feature.getProperty(prop) }),
          {}
        );

        props.isApprovalZone =
          approvalZones.findIndex((c) => c.mapCode === props.mapCode) > -1;
        feature.setProperty('props', props);
      }

      return {
        ...props,
        visible: !props.isApprovalZone || visibleCodes.includes(props.mapCode),
      };
    });
  }, [map, approvalZones]);

  return mapRef;
};

const flightRequestToShape = (google, flightRequest) => {
  const { pathMode, radiusMeters, coordinates, deadCenter } = flightRequest;
  let formattedCoordinates = [...coordinates];
  if (typeof coordinates[0][0] === 'object') {
    // eslint-disable-next-line prefer-destructuring
    formattedCoordinates = coordinates[0];
  }

  if (pathMode === 'Polygon') {
    return new google.maps.Polygon({
      paths: formattedCoordinates?.map(([lng, lat]) => ({ lng, lat })),
      ...flightPathShapeOptions,
    });
  }

  if (pathMode === 'Circle') {
    const [lng, lat] = deadCenter;
    return new google.maps.Circle({
      center: { lat, lng },
      radius: radiusMeters,
      ...flightPathShapeOptions,
    });
  }

  if (pathMode === 'Waypoint') {
    return new google.maps.Polyline({
      path: coordinates.map(([lng, lat]) => ({ lng, lat })),
      geodesic: true,
      ...flightPathShapeOptions,
    });
  }

  if (pathMode === 'Segmented') {
    const [lng, lat] = deadCenter;
    return new google.maps.Circle({
      center: { lat, lng },
      radius: radiusMeters ?? 100,
      ...flightPathShapeOptions,
    });
  }

  throw new Error('Unknown path mode for flight request');
};

const flightPathShapeOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0,
  zIndex: 1,
};
