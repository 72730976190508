import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { childrenPropType } from 'airshare-web-utils/prop-types';

import './panel.scss';

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.innerContentRef = React.createRef();
    this.state = {
      isCollapsed: false,
      contentHeight: null,
    };
  }

  componentDidMount() {
    const { isCollapsible, startCollapsed } = this.props;

    window.addEventListener('resize', this.setHeight);
    this.setState({ isCollapsed: isCollapsible ? startCollapsed : false });
    this.setHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight);
  }

  toggle = () => {
    const { isCollapsible } = this.props;

    if (isCollapsible) {
      const { isCollapsed } = this.state;
      this.setState({ isCollapsed: !isCollapsed });
      this.setHeight();
    }
  };

  setHeight = () => {
    const { isCollapsible } = this.props;

    if (!isCollapsible) {
      return;
    }

    // Execute in an immediate timeout to allow
    // for state to have been updated by calling
    // functions
    setTimeout(() => {
      let contentHeight;
      const { isCollapsed } = this.state;

      if (isCollapsed) {
        contentHeight = 0;
      } else {
        const { height } = window.getComputedStyle(
          this.innerContentRef.current
        );
        const adjustedHeight =
          Number.parseInt(height.replace('px', ''), 10) + 26; // HACK to account for top and bottom padding
        contentHeight = `${adjustedHeight}px`;
      }

      this.setState({ contentHeight });
    });
  };

  render() {
    const { children, className, boxed, isCollapsible, title } = this.props;

    const { isCollapsed, contentHeight } = this.state;

    return (
      <div
        className={classnames(className, 'airshare-panel', {
          'is-collapsed': isCollapsed,
          'is-collapsible': isCollapsible,
          boxed,
        })}
      >
        {title && (
          <div className="panel-title heading-s" onClick={this.toggle}>
            {title}
          </div>
        )}
        <div className="panel-content" style={{ height: contentHeight }}>
          <div className="panel-inner-content" ref={this.innerContentRef}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

Panel.propTypes = {
  boxed: PropTypes.bool,
  className: PropTypes.string,
  children: childrenPropType.isRequired,
  startCollapsed: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Panel.defaultProps = {
  className: null,
  boxed: false,
  startCollapsed: false,
  isCollapsible: false,
  title: [],
};

export default Panel;
